import * as PIXI from "pixi.js";
import app from "./../App";

import Helper from "./../Helper";
import { Enemy } from "./Enemy";
import ennemiSprite from "@/assets/game/MICROPLASTIC/microplastic_1_2_2x.png";

export class OrangePlastic extends Enemy {
    constructor(isSmall, position, direction) {
        super(isSmall);
        
        this.enemySprite = ennemiSprite;

        this.init(position, direction)
    }

    spreadChildren() {
        super.spreadChildren();
        app.gameManager.addNewEnemy(1, true, {x: this.sprite.x, y: this.sprite.y}, {x: -1, y: 0})
        app.gameManager.addNewEnemy(1, true, {x: this.sprite.x, y: this.sprite.y}, {x: 1, y: 0})
    }
}
