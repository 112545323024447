import * as PIXI from "pixi.js";
import app from "./App";

import ennemiSprite from "@/assets/game/ennemi.png";
import { Bubble } from "./Bubble";
import Helper from "./Helper";
import { OrangePlastic } from "./enemies/OrangePlastic";
import { RedPlastic } from "./enemies/RedPlastic";
import { YellowPlastic } from "./enemies/YellowPlastic";

export class GameManager {
    constructor() {
        // BUBBLES
        this.spawnInterval = 500; // interval de temps entre les bulles
        this.initialSpawnInterval = 600; // interval de temps entre les bulles au début du jeu 
        this.bubbles = [];

        // ENEMIES
        this.enemies = [];
        this.spawnAreaWidthPercentage = 0.7; // 70% de la largeur de l'écran
        this.spawnAreaHeightPercentage = 0.5; // 70% de la hauteur de l'écran
        this.marginLeft = 30;
        this.marginTop = 30;

        this.enemiesInitialSpawnInterval = 2500;

        // avalaibleEnemies 0->yellow, 1->orange, 2->red
        this.enemyWavesDatas = [
            {
                scoreLimit: 0,
                avalaibleEnemies: [0],
                blueBackgroundOpacity: 0,
                vignetteBackgroundOpacity: 0,
                bubbleSpawnInterval: 600
            },
            {
                scoreLimit: 2500,
                avalaibleEnemies: [0, 1],
                blueBackgroundOpacity: 0.2,
                vignetteBackgroundOpacity: 0.3,
                bubbleSpawnInterval: 700
            },
            {
                scoreLimit: 5000,
                avalaibleEnemies: [1],
                blueBackgroundOpacity: 0.4,
                vignetteBackgroundOpacity: 0.6,
                bubbleSpawnInterval: 700
            },
            {
                scoreLimit: 7500,
                avalaibleEnemies: [1, 2],
                blueBackgroundOpacity: 0.6,
                vignetteBackgroundOpacity: 0.8,
                bubbleSpawnInterval: 800
            },
            {
                scoreLimit: 10000,
                avalaibleEnemies: [2],
                blueBackgroundOpacity: 0.8,
                vignetteBackgroundOpacity: 1,
                bubbleSpawnInterval: 1000
            },
        ]

        if (app.tablet) {
            this.spawnInterval = 700; // interval de temps entre les bulles
            this.initialSpawnInterval = 700; // interval de temps entre les bulles au début du jeu 
            this.enemiesInitialSpawnInterval = 700;

            this.enemyWavesDatas = [
                {
                    scoreLimit: 0,
                    avalaibleEnemies: [0],
                    blueBackgroundOpacity: 0,
                    vignetteBackgroundOpacity: 0,
                    bubbleSpawnInterval: 800,
                    enemySpawnInterval: 400,
                },
                {
                    scoreLimit: 2500,
                    avalaibleEnemies: [0, 1],
                    blueBackgroundOpacity: 0.2,
                    vignetteBackgroundOpacity: 0.3,
                    bubbleSpawnInterval: 1000,
                    enemySpawnInterval: 400,
                },
                {
                    scoreLimit: 5000,
                    avalaibleEnemies: [1],
                    blueBackgroundOpacity: 0.4,
                    vignetteBackgroundOpacity: 0.6,
                    bubbleSpawnInterval: 1200,
                    enemySpawnInterval: 300,
                },
                {
                    scoreLimit: 7500,
                    avalaibleEnemies: [1, 2],
                    blueBackgroundOpacity: 0.6,
                    vignetteBackgroundOpacity: 0.8,
                    bubbleSpawnInterval: 1400,
                    enemySpawnInterval: 200,
                },
                {
                    scoreLimit: 10000,
                    avalaibleEnemies: [2],
                    blueBackgroundOpacity: 0.8,
                    vignetteBackgroundOpacity: 1,
                    bubbleSpawnInterval: 1600,
                    enemySpawnInterval: 100,
                },
            ]
        }

        this.enemyWavesState = 0;
        this.avalaibleEnemies = [0];
        this.currentWaveIndex = 0

        app.ticker.add(() => {
            this.updateEnemiesState()
        });
        
        this.lastTimeWhenEnemiesWasChecked = app.ticker.lastTime;
    }

    updateEnemiesState() {
        if (app.ticker.lastTime - this.lastTimeWhenEnemiesWasChecked < 1000) {
            return;
        }
        this.lastTimeWhenEnemiesWasChecked = app.ticker.lastTime;

        let finalWaveIndex = 0
        // si le score est superieur au derniers limitie
        if (app.ui.score > this.enemyWavesDatas[this.enemyWavesDatas.length - 1].scoreLimit) {
            let finalWave = this.enemyWavesDatas[this.enemyWavesDatas.length - 1];

            this.avalaibleEnemies = [0,1,2];
            app.scene.blueFlatBackground.opacity = finalWave.blueBackgroundOpacity;
            app.ui.vignetteBackground.opacity = finalWave.vignetteBackgroundOpacity;

            
            if (!app.tablet) {
                this.enemiesInitialSpawnInterval = 2500 * (1 - Helper.remap(app.ui.score - finalWave.scoreLimit, 0, 5000, 0, 0.8))
                if (this.enemiesInitialSpawnInterval < 500) this.enemiesInitialSpawnInterval = 500;
            }
            else {
                this.enemiesInitialSpawnInterval = finalWave.enemySpawnInterval
            }

            app.player.touchedEnemyIntervalLimit = 250;
            return
        }

        for (let i = 0; i < this.enemyWavesDatas.length; i++) {
            const waveD = this.enemyWavesDatas[i];
            if (app.ui.score < waveD.scoreLimit) {
                break;
            }
            finalWaveIndex = i;
        }
        if (this.currentWaveIndex < finalWaveIndex) {
            app.ui.displayLevelUp();
            this.currentWaveIndex = finalWaveIndex;
        }

        
        if(this.currentWaveIndex == this.enemyWavesDatas.length) app.player.touchedEnemyIntervalLimit = 500;
        this.avalaibleEnemies = this.enemyWavesDatas[this.currentWaveIndex].avalaibleEnemies;
        this.initialSpawnInterval = this.enemyWavesDatas[this.currentWaveIndex].bubbleSpawnInterval;
        this.enemySpawnInterval = this.enemyWavesDatas[this.currentWaveIndex].enemySpawnInterval
        app.scene.blueFlatBackground.opacity = this.enemyWavesDatas[this.currentWaveIndex].blueBackgroundOpacity;
        app.ui.vignetteBackground.opacity = this.enemyWavesDatas[this.currentWaveIndex].vignetteBackgroundOpacity;
    }

    /**
     * create and return a new enemy 
     * @param {Number} type 0 - 2 for different kind of enemy  
     * @param {Boolean} isSmall is it a small ?
     * @param {Object} position {x, y}
     */
    addNewEnemy(type, isSmall = false, position = null, direction = null) {
        if (isSmall) {
            if (type == 0) this.enemies.push(new YellowPlastic(isSmall, position, direction));
            if (type == 1) this.enemies.push(new OrangePlastic(isSmall, position, direction));
            if (type == 2) this.enemies.push(new RedPlastic(isSmall, position, direction));
        }
        else {
            if (type == 0) this.enemies.push(new YellowPlastic(isSmall));
            if (type == 1) this.enemies.push(new OrangePlastic(isSmall));
            if (type == 2) this.enemies.push(new RedPlastic(isSmall));
        }
    }

    startSpawningEnemies() {
        app.ticker.add(() => {
            this.spawnEnemy()
        });

        this.lastTimeWhenEnemiesSpawned = app.ticker.lastTime;
    }

    spawnEnemy() {
        if (app.ticker.lastTime - this.lastTimeWhenEnemiesSpawned < this.enemiesInitialSpawnInterval) {
            return;
        }
        this.lastTimeWhenEnemiesSpawned = app.ticker.lastTime;

        if (this.avalaibleEnemies.length > 1) {
            this.addNewEnemy(Helper.randomIntFromInterval(this.avalaibleEnemies[0], this.avalaibleEnemies[1]), false)
        } else {
            this.addNewEnemy(this.avalaibleEnemies[0], false)
        }
    }

    startSpawningBubbles() {
        app.ticker.add(() => {
            this.addNewBubble();
        });

        this.lastTimeWhenBubblesSpawned = app.ticker.lastTime;
    }

    addNewBubble() {
        if (app.ticker.lastTime - this.lastTimeWhenBubblesSpawned < this.initialSpawnInterval) {
            return;
        }
        this.lastTimeWhenBubblesSpawned = app.ticker.lastTime;
        
        if (this.bubbles.length > 100) return;

        let bubble = new Bubble();
        app.gameContainer.addChild(bubble.sprite);
        this.bubbles.push(bubble);
    }


    //--------------------------------------------------------------------------------------//
    //                                       HELPERS                                        //
    //--------------------------------------------------------------------------------------//


    //vérifier que les bulles ne swpan pas sur le plankton  

}