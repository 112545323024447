<template>
    <div class="page-chooseplankton">

        <section>

            <div id="logoTuto">
                <a :href="$t('HOME_PAGE.BIOTHERM_LINK')" target="_blank">
                    <svg width="67" height="12" viewBox="0 0 67 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_436_6757)">
                            <path d="M10.696 0.205505H8.65881V11.8072H10.696V0.205505Z" fill="white" />
                            <path
                                d="M23.8858 5.96702C23.8858 2.7019 21.3996 0 17.8376 0C14.2757 0 11.7539 2.73488 11.7539 6V6.03298C11.7539 9.2981 14.2402 12 17.8046 12C21.3691 12 23.8858 9.26512 23.8858 6V5.96702ZM21.7471 6.03298C21.7471 8.28837 20.1387 10.1099 17.8351 10.1099C15.5315 10.1099 13.8901 8.25285 13.8901 6V5.96702C13.8901 3.71163 15.4985 1.89006 17.8021 1.89006C20.1057 1.89006 21.7471 3.74715 21.7471 6V6.03298Z"
                                fill="white" />
                            <path d="M26.2807 11.8021H28.3205V2.08794H31.0351V0.197876H23.5662V2.08794H26.2807V11.8021Z"
                                fill="white" />
                            <path
                                d="M34.1074 6.91077H38.8592V11.8021H40.8989H45.978V9.978H40.8989V6.87779H44.1666V5.03847H40.8989V2.02198H45.8943V0.197876H40.8989H38.8592V5.02325H34.1074V0.197876H32.0702V11.8021H34.1074V6.91077Z"
                                fill="white" />
                            <path
                                d="M2.03721 11.8021H3.85116C6.16237 11.8021 7.60085 10.4905 7.60085 8.50401V8.4761C7.60085 7.24312 7.04778 6.35517 6.18266 5.81225C6.89302 5.27187 7.36237 4.51331 7.36237 3.52642V3.49851C7.36237 1.5095 5.92389 0.197876 3.61268 0.197876H0V11.7996H2.03721V11.8021ZM2.03721 2.0575H3.67865C4.83552 2.0575 5.52051 2.57758 5.52051 3.53149V3.55686C5.52051 4.4575 4.81015 5.09174 3.69133 5.09174H2.03721V2.0575ZM2.03721 6.81437H3.93235C5.05116 6.81437 5.76152 7.54756 5.76152 8.44819V8.47356C5.76152 9.42747 5.07653 9.94756 3.91966 9.94756H2.03721V6.81437Z"
                                fill="white" />
                            <path
                                d="M52.1277 6.59872C53.4215 6.12938 54.3171 5.09428 54.3171 3.52388V3.49597C54.3171 1.5095 52.8786 0.197876 50.5674 0.197876H46.9547V11.7996H48.9919V6.89301H50.0955L52.5995 11.7996H54.8169L52.5386 7.3928L52.1277 6.59872ZM50.6486 5.03847H48.9945V2.05496H50.6359C51.7928 2.05496 52.4778 2.57504 52.4778 3.52895V3.55432C52.4778 4.45496 51.7674 5.03847 50.6486 5.03847Z"
                                fill="white" />
                            <path
                                d="M65.9087 0H65.6829L61.1366 6.88795L56.5877 0H56.3619L55.3319 11.8072H57.3285L57.912 5.27696L61.0249 10.0034H61.2457L64.3585 5.27696L64.9421 11.8072H66.9387L65.9087 0Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_436_6757">
                                <rect width="66.9387" height="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>


            <p id="choose" v-html='$t("CHOOSE_PLANKTON_PAGE.TITLE")'></p>

            <!-- Swiper -->
            <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <video id="choosevideo1" autoplay loop muted playsinline>
                            <source src="/video/webm/Diatom-Splash.webm" type="video/webm" />
                            <source src="/video/Diatom-Splash-1.mov" type="video/mp4" />
                        </video>
                        <p class="txtTuto" v-html='$t("CHOOSE_PLANKTON_PAGE.DIATOM")'></p>
                    </div>

                    <div class="swiper-slide">
                        <video id="choosevideo1" autoplay loop muted playsinline>
                            <source src="/video/webm/Coccolitophore-Splash.webm" type="video/webm" />
                            <source src="/video/Coccolitophore-Splash-1.mov" type="video/mp4" />
                        </video>
                        <p class="txtTuto" v-html='$t("CHOOSE_PLANKTON_PAGE.COCCOLITHOPHORE")'>
                        </p>
                    </div>

                    <div class="swiper-slide">
                        <video id="choosevideo1" autoplay loop muted playsinline>
                            <source src="/video/webm/Dinophyta-Splash.webm" type="video/webm" />
                            <source src="/video/Dinophyta-Splash-1.mov" type="video/mp4" />
                        </video>
                        <p class="txtTuto" v-html='$t("CHOOSE_PLANKTON_PAGE.DINOPHYTA")'>
                        </p>
                    </div>
                </div>

                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>

            </div>



        </section>

        <div class="tutoBottomGrid">
            <div class="tutoPagination">
                <!-- <div class="swiper-pagination"></div> -->
            </div>
            <div class="tutoButton">
                <div class="continueButton" v-on:click="handleContinueButtonClick">
                    {{ $t("CHOOSE_PLANKTON_PAGE.BOUTON") }}
                </div>
            </div>

            <!-- <div class="tutoSkip">
                <svg width="67" height="12" viewBox="0 0 67 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_436_6757)">
                        <path d="M10.696 0.205505H8.65881V11.8072H10.696V0.205505Z" fill="white" />
                        <path
                            d="M23.8858 5.96702C23.8858 2.7019 21.3996 0 17.8376 0C14.2757 0 11.7539 2.73488 11.7539 6V6.03298C11.7539 9.2981 14.2402 12 17.8046 12C21.3691 12 23.8858 9.26512 23.8858 6V5.96702ZM21.7471 6.03298C21.7471 8.28837 20.1387 10.1099 17.8351 10.1099C15.5315 10.1099 13.8901 8.25285 13.8901 6V5.96702C13.8901 3.71163 15.4985 1.89006 17.8021 1.89006C20.1057 1.89006 21.7471 3.74715 21.7471 6V6.03298Z"
                            fill="white" />
                        <path d="M26.2807 11.8021H28.3205V2.08794H31.0351V0.197876H23.5662V2.08794H26.2807V11.8021Z"
                            fill="white" />
                        <path
                            d="M34.1074 6.91077H38.8592V11.8021H40.8989H45.978V9.978H40.8989V6.87779H44.1666V5.03847H40.8989V2.02198H45.8943V0.197876H40.8989H38.8592V5.02325H34.1074V0.197876H32.0702V11.8021H34.1074V6.91077Z"
                            fill="white" />
                        <path
                            d="M2.03721 11.8021H3.85116C6.16237 11.8021 7.60085 10.4905 7.60085 8.50401V8.4761C7.60085 7.24312 7.04778 6.35517 6.18266 5.81225C6.89302 5.27187 7.36237 4.51331 7.36237 3.52642V3.49851C7.36237 1.5095 5.92389 0.197876 3.61268 0.197876H0V11.7996H2.03721V11.8021ZM2.03721 2.0575H3.67865C4.83552 2.0575 5.52051 2.57758 5.52051 3.53149V3.55686C5.52051 4.4575 4.81015 5.09174 3.69133 5.09174H2.03721V2.0575ZM2.03721 6.81437H3.93235C5.05116 6.81437 5.76152 7.54756 5.76152 8.44819V8.47356C5.76152 9.42747 5.07653 9.94756 3.91966 9.94756H2.03721V6.81437Z"
                            fill="white" />
                        <path
                            d="M52.1277 6.59872C53.4215 6.12938 54.3171 5.09428 54.3171 3.52388V3.49597C54.3171 1.5095 52.8786 0.197876 50.5674 0.197876H46.9547V11.7996H48.9919V6.89301H50.0955L52.5995 11.7996H54.8169L52.5386 7.3928L52.1277 6.59872ZM50.6486 5.03847H48.9945V2.05496H50.6359C51.7928 2.05496 52.4778 2.57504 52.4778 3.52895V3.55432C52.4778 4.45496 51.7674 5.03847 50.6486 5.03847Z"
                            fill="white" />
                        <path
                            d="M65.9087 0H65.6829L61.1366 6.88795L56.5877 0H56.3619L55.3319 11.8072H57.3285L57.912 5.27696L61.0249 10.0034H61.2457L64.3585 5.27696L64.9421 11.8072H66.9387L65.9087 0Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_436_6757">
                            <rect width="66.9387" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div> -->
        </div>
    </div>
</template>




<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export default {
    name: "pageChoosePlankton",
    data() {
        return {
            swiper: null,
            planktonIndex: 0
        };
    },
    mounted() {
        this.$root.showNav = false;
        this.initSwiper();
    },
    beforeUnmount() {
        this.$root.showNav = true;
        this.swiper.off("slideChange", this.handleSlideChange);
    },
    methods: {
        initSwiper() {
            this.swiper = new Swiper(".mySwiper", {
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });

            this.swiper.on("slideChange", this.handleSlideChange);
            window.localStorage.setItem("planktonIndex", this.swiper.realIndex);
        },
        handleContinueButtonClick() {
            this.$router.push("/tuto");
            // this.$router.push("/loading");
        },
        handleSlideChange() {
            window.localStorage.setItem("planktonIndex", this.swiper.realIndex);
        }
    }
};
</script>


<style scoped>
/* iPhone SE */
@media only screen and (max-width: 380px) {
    section {
        padding-top: 2%;
    }

    #choose {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0;
        text-shadow: none;
        margin: 10px;
    }

    .continueButton {
        margin-top: 20px;
        padding: 12px 22px;
        font-size: 10px;
    }

    .txtTuto {
        font-size: 12px;
        line-height: 12px;
    }

    #choosevideo1 {
        width: 49%;
        position: relative;
        margin: auto;
        left: 0;
        right: 0;
        top: 12%;
        z-index: 1;

    }

}


/* iPhone */
@media only screen and (max-width: 600px) and (min-width: 381px) {
    #choose {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 1.6px;
        text-align: center;
    }

    .txtTuto {
        text-align: center;
        font-family: "FuturaLTLight";
        color: white;
        font-weight: 200;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.75px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 30px;
    }

    .continueButton {
        margin-top: 0;
    }
}




.page-chooseplankton {
    background-image: radial-gradient(70% 40% at 50% 47%,
            #063770 0%,
            #073aff00 100%),
        radial-gradient(113% 91% at 17% -2%, #05376b 1%, #ff000000 99%),
        radial-gradient(142% 91% at 83% 7%, #025e9f 1%, #ff000000 99%),
        radial-gradient(142% 91% at -6% 74%, #0b5394 1%, #ff000000 99%),
        radial-gradient(142% 91% at 111% 84%, #0e85ce 0%, #0e85ce 100%);

    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
}

#logoTuto {
    justify-self: center;
    align-self: center;
}

#choosevideo1 {
    width: 55%;
    position: relative;
    margin: auto;
    left: 0;
    right: 0;
    top: 12%;
    z-index: 1;

}



/* #choose {
    text-align: center;
    font-family: "FuturaLTMedium";
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 1.6px;
    text-align: center;
} */

.continueButton {
    background: #00263A;
    /* width: 150px; */
    position: relative;
    z-index: 10;
    /* margin-top: 30px; */
}

.swiper {
    width: 85%;
    height: 54vh;
}

.swiper-slide {
    background: none;
    display: grid;
}

.swiper-button-prev {
    color: #ffffff;
}

.swiper-button-next {
    color: #ffffff;
}






@media screen and (min-width: 40em) {

    svg {
        transform: scale(2.5);
    }

    /* #choose {
        font-size: 50px;
        width: 544px;
        display: block;
        margin: auto;
        padding-top: 7%;
        line-height: 69px;
        letter-spacing: 2.5px;
    } */
    #choose {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 69px;
        letter-spacing: 2.5px;
        text-align: center;
    }


    .continueButton {
        background: #00263A;
        /* width: 150px; */
        position: relative;
        z-index: 10;
        margin-top: 30px;
    }


    /* #choosevideo1 {
        width: 330px;
        padding-bottom: 20px;
    } */

    #choosevideo1 {
        width: 330px;
        padding-bottom: 20px;
        position: relative;
        margin: auto;
        left: 0;
        right: 0;
        top: 12%;
        z-index: 1;

    }

    .txtTuto {
        font-size: 20px;
        letter-spacing: 0.75px;
        line-height: 28px;
        width: 500px;
    }

    .tutoBottomGrid {
        grid-template-rows: 85px 124px 20px;
    }
}
</style>