<template>
    <div>
        <video autoplay loop muted playsinline v-if="blobs.length == 0" :class="videoClass">
            <source 
                v-for="(src, index) in sources" 
                :key="uniqueID + '-src-' + index"
                :src="src.path" 
                :type="src.type">
        </video>
        <video autoplay loop muted playsinline v-else-if="blobs[indexVideo]" :src="blobs[indexVideo].blobPath" :class="videoClass + ' cached-video'"></video>
    </div>
</template>




<script>
import { stringifyQuery } from 'vue-router';
import Video from './Video.vue';
import Helper from "@/game/Helper";


export default {
    name: "video",
    props: {
        sources: {
            type: Array,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
        videoClass: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            swiper: null,
            continueButton: null,
            blobs: [],
            indexVideo: 0
        };
    },
    mounted() {
        if (Helper.isSafari()) {
            this.indexVideo = 1;
        }

        for (let i = 0; i < this.sources.length; i++) {
            const path = this.$props.sources[i].path;
            const storedVideoTitle = this.uniqueId + "-" + i;
            
            const request = window.indexedDB.open('plankton', 1);

            request.onsuccess = event => {
                const db = event.target.result;

                const transaction = db.transaction(['videos']);
                const objectStore = transaction.objectStore('videos');

                const videoStored = objectStore.get(storedVideoTitle);

                videoStored.transaction.oncomplete = event => {
                    if (videoStored.result) {
                        this.blobs.push({
                            blobPath: window.URL.createObjectURL(videoStored.result.blob),
                            type: videoStored.result.blob.type
                        });
                    }
                };
            }
        }
    },
    methods: {
    }
};
</script>


<style scoped>
video{
    width: 100%;
}
</style>