<template>
    <loading :class="{ 'visible': loaderIsVisible}" @loading-over="startGame" ref="loaderComponent"/>
    <div class="page-game">
        <div ref="gameContainer" id="game-view">
        </div>
        <div class="gameover-overlay invisible" ref="gameoverOverlay">
            <!-- <p>{{ $t("IN_GAME.GAME_OVER_MESSAGE") }}</p> -->
        </div>
    </div>
</template>



<script>
import { initGame, destroyGame, startGame } from '@/game/game.js';
import loading from './loading.vue';

export default {
    name: "pageGame",
    data() {
        return {
            loaderIsVisible: true,
            timer: 0,
        }
    },
    components: {
        loading
    },
    mounted() {
        this.timer = new Date().getSeconds();
        this.$root.showNav = false;
        initGame(this.$router, this.$t, this.displayGameOverOverlay, this.$refs.loaderComponent.addTrueProgress, this.$refs.loaderComponent.skipLoading);
    },
    beforeUnmount() {
        destroyGame();
        this.$root.showNav = true;
    },
    methods: {
        startGame() {
            this.loaderIsVisible = false;
            startGame()
        },
        displayGameOverOverlay() {
            this.$refs.gameoverOverlay.classList.toggle("invisible")
            setTimeout(() => {
                this.$router.push({
                    name: "scorePage"
                })
            }, 3000);
        }
    }
};
</script>




<style lang="scss" scoped>
.gameContainer {
    /* background: #DBDFEA; */
    overflow: hidden;
    background: #01265c;
    min-height: 100vh;
}

@font-face {
    font-family: 'FuturaLTMedium';

    src: url('~@/assets/fonts/FuturaLTPro-Medium.otf') format('truetype');

    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FuturaLTLight";

    src: url("~@/assets/fonts/FuturaLTPro-Light.otf") format("truetype");

    font-weight: normal;
    font-style: normal;
}


#capture-btn {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 20px;
}

button {
    padding: 10px 15px;
    border: unset;
    border-radius: 15px;
    color: #212121;
    z-index: 1;
    background: #e8e8e8;
    position: relative;
    font-weight: 300;
    font-size: 14px;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
    overflow: hidden;
}

button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #212121;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms
}

button:hover {
    color: #e8e8e8;
}

button:hover::before {
    width: 100%;
}

.gameover-overlay {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #01265c;
    opacity: 0.5;
    left: 0;
    top: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.invisible {
        opacity: 0;
    }

    p {
        color: white;
    }

    transition: opacity 3s ease-in;
}
.visible{
    display: unset;
}

</style>