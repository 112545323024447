import Plausible from 'plausible-tracker';

export default {
  install: (app, options) => {
    const { enableAutoPageviews, trackEvent } = Plausible(options);

    enableAutoPageviews();
    window.trackEvent = trackEvent

    app.provide('plausible');
  },
};