
import * as PIXI from "pixi.js";

import app from "./App";

// vue
let vueRouter;
let $t;

import { Scene } from "./scene";
import { Player } from "./Player";
import { UI } from "./UI";
import { GameManager } from "./GameManager";
import { AudioManager } from "./AudioManager";
import { sound } from "@pixi/sound";
import { PlopParticule } from "./PlopParticule";
import { GodrayFilter } from "@pixi/filter-godray";
import { ShockwaveFilter } from "@pixi/filter-shockwave";
import Helper from "./Helper";

let timer = 0;

function initGame(router, _$t, onGameOverCallBack, addLoadingValueCallback, skipLoading) {
	timer = new Date();
	vueRouter = router;
	$t = _$t;

	app = new PIXI.Application({
		width: window.innerWidth,
		height: window.innerHeight,
		backgroundColor: 0x01265c,
		antialias: true,
		autoDensity: true,
		resolution: 2,
	})

	app.tablet = false;
	if (Helper.mobileIsTablet()) {
		app.tablet = true;
	}
	
	app.addLoadingValueCallback = addLoadingValueCallback;
	app.skipLoading = skipLoading;
	
	app.stage.eventMode = "dynamic"  //.interactive = true;
	
	app.shockWave = shockWave;
	app.shockWaveFilter = new ShockwaveFilter(
		new PIXI.Point(-100, -100),
		{
			animating: true,
			wavelength: 62.175,
			speed: 156,
			brightness: 1.08,
			radius: 332,
		},
		0
	)
	// app.stage.filters = [
	// ];

	app.backgroundContainer = new PIXI.Container();
	app.stage.addChild(app.backgroundContainer);
	app.backgroundContainer.filters = [
		new GodrayFilter({
			time: 0.3,
			animating: true,
			lacunarity: 2.5,
			alpha: 0.6,
			angle: 2.04
		}),
		app.shockWaveFilter
	];

	app.gameContainer = new PIXI.Container();
	app.stage.addChild(app.gameContainer);
	
	app.uperGameContainer = new PIXI.Container();
	app.stage.addChild(app.uperGameContainer);

	app.uiContainer = new PIXI.Container();
	app.uiContainer.eventMode = "none"
	app.stage.addChild(app.uiContainer);

	app.gameOver = gameOver;
	app.onGameOverCallBack = onGameOverCallBack;
	app.gameOVerWasTrigger = false

	setupGame();

	document.getElementById("game-view").appendChild(app.view);

	document.addEventListener("visibilitychange", () => {
		if (document.hidden) {
			PIXI.Ticker.shared.stop();
			sound.pauseAll();
		} else {
			PIXI.Ticker.shared.start();
			sound.resumeAll();
		}
	});
}

function startGame() {
	app.player.displayPlayer();
	app.audioManager.startAmbiance();
}


export { initGame, destroyGame, startGame };



let ui;

async function setupGame() {
	app.scene = new Scene();

	app.ui = new UI($t, destroyGame, vueRouter);

	app.gameManager = new GameManager();

	app.audioManager = new AudioManager();
	app.player = new Player();
	await app.player.initPlanktonSprites()

}

function destroyGame() {
	if (!app) return;
	app.ticker.stop();
	app.audioManager.stopAllSounds();
	app.destroy(true);
}

function gameOver() {
	if (app.gameOVerWasTrigger) return

	app.gameOVerWasTrigger = true
	app.shockWaveFilter.center = new PIXI.Point(app.player.plankton.x, app.player.plankton.y);
	app.shockWave();

	
	app.player.touchable = false;

	app.audioManager.playFX("gameover");
	window.localStorage.setItem("score", app.ui.score);
	window.trackEvent("GameOver", {
		props: {
			duration: new Date(new Date() - timer).getSeconds(),
			score: app.ui.score,
			gameVersion: app.tablet ? "phone" : "tablet"
		}
	})

	// get all scores, add current
	if (!window.localStorage.getItem("allScores")) {
		Helper.defineDefaultScores();
	}
	let scores = JSON.parse(window.localStorage.getItem("allScores"));
	scores.push(app.ui.score);
	window.localStorage.setItem("allScores", JSON.stringify(scores));

	app.onGameOverCallBack(app.ui.score);
}

function shockWave() {
	app.ticker.add((delta) => {
		app.shockWaveFilter.time += 0.02
		if (app.shockWaveFilter.time > 3) {
			app.shockWaveFilter.time = 0
		}
	})
}