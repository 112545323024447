import * as PIXI from "pixi.js";
import app from "./App";

import { Sprite, getBlendFilter } from '@pixi/picture';

import fond1 from "@/assets/game/fond1.png";
import fond2 from "@/assets/game/fond2.png";
import PlantsSprite from "@/assets/game/plants.png";
import Helper from "./Helper";

export class Scene {
    constructor() {
        this.initBackground();
        this.initPlants();
        
        app.ticker.add(() => {
            this.updateBackground()
        });
    }

    initBackground() {
        //const bg = PIXI.Sprite.from('https://cdn.glitch.global/9f696fa5-3554-4261-8568-a0a1d8fcc186/fond2.png?v=1681416104655');
        this.bg = PIXI.Sprite.from(fond1);

        this.bg.width =  app.screen.width;
        this.bg.height =  app.screen.height * 4;

        app.backgroundContainer.addChild(this.bg);

        this.bgSpeed = 0.6; // change la vitesse du déplacement

        // Create a second background sprite (this.bg2) for seamless scrolling
        //const bg2 = PIXI.Sprite.from('https://cdn.glitch.global/9f696fa5-3554-4261-8568-a0a1d8fcc186/fond2.png?v=1681416104655');
        this.bg2 = PIXI.Sprite.from(fond2);
        this.bg2.width =  app.screen.width;
        this.bg2.height =  app.screen.height * 4;
        this.bg2.y = -this.bg2.height;

        app.backgroundContainer.addChild(this.bg2);

        // dynamic background
        this.blueFlatBackground = {}
        this.blueFlatBackground.opacity = 0
        this.blueFlatBackground.tempOpacity = 0
        this.blueFlatBackground.sprite = new PIXI.Graphics()
        this.updateBlueBackground()
        this.blueFlatBackground.sprite.filters = [getBlendFilter(PIXI.BLEND_MODES.DARKEN)];
        app.backgroundContainer.addChild(this.blueFlatBackground.sprite);
        app.ticker.add((time) => {
            let distanceBetween = Math.abs(this.blueFlatBackground.opacity - this.blueFlatBackground.tempOpacity)
            if (distanceBetween > 0.01) {
                this.blueFlatBackground.tempOpacity = Helper.lerp(
                    this.blueFlatBackground.tempOpacity,
                    this.blueFlatBackground.opacity,
                    0.05)
                this.updateBlueBackground()
            }
        })
    }

    updateBackground() {
        this.bg.y += this.bgSpeed;
        this.bg2.y += this.bgSpeed;

        if (this.bg.y > this.bg.height) {
            this.bg.y = this.bg2.y - this.bg.height;
        }

        if (this.bg2.y > this.bg2.height) {
            this.bg2.y = this.bg.y - this.bg2.height;
        }
    }

    updateBlueBackground() {
        this.blueFlatBackground.sprite.clear();
        this.blueFlatBackground.sprite.beginFill("#0c2964", this.blueFlatBackground.tempOpacity);
        this.blueFlatBackground.sprite.drawRect(0, 0, app.view.width, app.view.height);
        this.blueFlatBackground.sprite.endFill();
    }

    initPlants() {
        this.plants = PIXI.Sprite.from(PlantsSprite);

        this.plants.position.y = -1100;
        this.plants.position.x = -260;
        this.plants.width = 400;
        this.plants.height = 1065;

        app.uiContainer.addChild(this.plants);

        this.vitesse = 1.5; // La vitesse à laquelle le sprite se déplace.
        this.aDroite = false;
        this.plants.eventMode = "none"

        // Check for collisions in the Ticker
        this.frameCount = 0;

        app.ticker.add(() => this.updatePlants())
    }

    updatePlants() {
        this.frameCount++;

        //plants
        this.plants.position.y += this.vitesse;

        // Si le sprite est sorti de l'écran en bas.
        if (this.plants.position.y > app.screen.height) {
            this.plants.position.y = 0; // Le réinitialiser en haut de l'écran.
            if (!this.aDroite) {
                this.plants.position.y = -1100;
                this.plants.position.x = app.screen.width - this.plants.width + 280;
                this.aDroite = true;
            } else {
                this.plants.position.y = -1100;
                this.plants.position.x = -260;
                this.aDroite = false;
            }
        }
    }
}
