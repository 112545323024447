import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/styles.css";

import { createI18n } from 'vue-i18n'
import { messages } from './assets/localization';
import './registerServiceWorker'

export const i18n = createI18n({
	locale: getLang(), // set locale
	fallbackLocale: 'en', // set fallback locale
	messages,
    missingWarn: false, 
    fallbackWarn: false,
    silentTranslationWarn: true,
})

export const vueApp = createApp(App)

import plausible from './plugins/plausible';

import Video from "./components/Video.vue"
vueApp.component("cached-video", Video)

vueApp.use(plausible, {
	domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN,
	hashMode: true,
	trackLocalhost: false,
})

vueApp.use(i18n)
vueApp.use(router)

vueApp.mount('#app')

function getLang() {
	if (navigator.languages != undefined) 
	  	return navigator.languages[0].slice(0,2); 
	return navigator.language.slice(0,2);
}