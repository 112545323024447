<template>
    <div class="page-scorecard" v-on:load="generateImgCard">
        <!-- bouton share score card -->
        <!-- <div id="btnDownload" v-on:click="downloadImage()">
            <svg width=" 40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#00263A" />
                <g clip-path="url(#clip0_528_7114)">
                    <path
                        d="M20.0388 26.1928C20.0444 26.1985 20.0523 26.2032 20.0579 26.2089C20.0826 26.2325 20.1082 26.2551 20.1366 26.2744C20.1497 26.2829 20.1641 26.289 20.1781 26.2971C20.2014 26.3107 20.2238 26.3244 20.2489 26.3353C20.2652 26.3419 20.2834 26.3456 20.3002 26.3518C20.3234 26.3593 20.3463 26.3683 20.3705 26.3735C20.4129 26.3819 20.4562 26.3867 20.4995 26.3867C20.5047 26.3867 20.5089 26.3852 20.514 26.3848C20.5522 26.3838 20.5904 26.3805 20.6281 26.373C20.6616 26.3664 20.6929 26.3551 20.7241 26.3437C20.7325 26.3404 20.7413 26.339 20.7497 26.3357C20.7865 26.3206 20.8205 26.3013 20.8536 26.2796C20.8559 26.2777 20.8592 26.2768 20.8615 26.2754C20.8983 26.2504 20.9318 26.2225 20.9626 26.1909L27.0548 20.0235C27.3096 19.766 27.3096 19.3477 27.0548 19.0897C26.8 18.8318 26.3873 18.8318 26.1325 19.0897L21.1517 24.131V9.66023C21.1517 9.29569 20.8596 9 20.4995 9C20.1394 9 19.8474 9.29569 19.8474 9.66023V24.1325L14.8661 19.0892C14.6113 18.8313 14.1986 18.8313 13.9438 19.0892C13.689 19.3467 13.689 19.765 13.9438 20.023L20.0379 26.1933L20.0388 26.1928ZM30.3478 24.7177C29.9877 24.7177 29.6957 25.0134 29.6957 25.3779V28.6791H11.3043V25.3779C11.3043 25.0134 11.0127 24.7177 10.6522 24.7177C10.2916 24.7177 10 25.0134 10 25.3779V29.3393C10 29.7038 10.2921 29.9995 10.6522 29.9995H30.3478C30.7079 29.9995 31 29.7038 31 29.3393V25.3779C31 25.0134 30.7079 24.7177 30.3478 24.7177Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_528_7114">
                        <rect width="21" height="21" fill="white" transform="translate(10 9)" />
                    </clipPath>
                </defs>
            </svg>
        </div> -->
        <div id="btnShare" v-on:click="shareScoreImage()">
            <svg width=" 40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#00263A" />
                <g clip-path="url(#clip0_528_7114)">
                    <path
                        d="M20.0388 26.1928C20.0444 26.1985 20.0523 26.2032 20.0579 26.2089C20.0826 26.2325 20.1082 26.2551 20.1366 26.2744C20.1497 26.2829 20.1641 26.289 20.1781 26.2971C20.2014 26.3107 20.2238 26.3244 20.2489 26.3353C20.2652 26.3419 20.2834 26.3456 20.3002 26.3518C20.3234 26.3593 20.3463 26.3683 20.3705 26.3735C20.4129 26.3819 20.4562 26.3867 20.4995 26.3867C20.5047 26.3867 20.5089 26.3852 20.514 26.3848C20.5522 26.3838 20.5904 26.3805 20.6281 26.373C20.6616 26.3664 20.6929 26.3551 20.7241 26.3437C20.7325 26.3404 20.7413 26.339 20.7497 26.3357C20.7865 26.3206 20.8205 26.3013 20.8536 26.2796C20.8559 26.2777 20.8592 26.2768 20.8615 26.2754C20.8983 26.2504 20.9318 26.2225 20.9626 26.1909L27.0548 20.0235C27.3096 19.766 27.3096 19.3477 27.0548 19.0897C26.8 18.8318 26.3873 18.8318 26.1325 19.0897L21.1517 24.131V9.66023C21.1517 9.29569 20.8596 9 20.4995 9C20.1394 9 19.8474 9.29569 19.8474 9.66023V24.1325L14.8661 19.0892C14.6113 18.8313 14.1986 18.8313 13.9438 19.0892C13.689 19.3467 13.689 19.765 13.9438 20.023L20.0379 26.1933L20.0388 26.1928ZM30.3478 24.7177C29.9877 24.7177 29.6957 25.0134 29.6957 25.3779V28.6791H11.3043V25.3779C11.3043 25.0134 11.0127 24.7177 10.6522 24.7177C10.2916 24.7177 10 25.0134 10 25.3779V29.3393C10 29.7038 10.2921 29.9995 10.6522 29.9995H30.3478C30.7079 29.9995 31 29.7038 31 29.3393V25.3779C31 25.0134 30.7079 24.7177 30.3478 24.7177Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_528_7114">
                        <rect width="21" height="21" fill="white" transform="translate(10 9)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <!-- bouton share score card -->
        <img id="fondScoreCard" src="@/assets/fond-scorecard2.png" v-if="htmlIsVisible">

        <div class="gridScoreCard" v-if="htmlIsVisible">
            <div class="gridScoreCardBlank1">

            </div>
            <div class="gridScoreCardScore">
                <p id="lifeplankton" v-html='$t("SCORE_CARD_PAGE.TITLE")'></p>
                <p id="score">{{ score }}</p>
            </div>
            <div class="gridScoreCardCoeur">
                <img id="score-image" :src="scoreImageURL" /><br>
            </div>
            <div class="gridScoreCardBouton">
            </div>
            <div class="gridScoreCardPlankton">
                <img src="@/assets/plankton-transparence-mobile.png">
            </div>

            <div class="gridScoreCardLogo">
                <svg width="120" height="50" viewBox="0 0 279 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_392_6549)">
                        <path d="M44.5665 0.856201H36.0781V49.1966H44.5665V0.856201Z" fill="white" />
                        <path
                            d="M99.5243 24.8626C99.5243 11.2579 89.1649 0 74.3234 0C59.482 0 48.9746 11.3953 48.9746 25V25.1374C48.9746 38.7421 59.334 50 74.186 50C89.038 50 99.5243 38.6046 99.5243 25V24.8626ZM90.6131 25.1374C90.6131 34.5349 83.9112 42.1247 74.3129 42.1247C64.7146 42.1247 57.8752 34.3869 57.8752 25V24.8626C57.8752 15.4651 64.5771 7.87526 74.1754 7.87526C83.7738 7.87526 90.6131 15.6131 90.6131 25V25.1374Z"
                            fill="white" />
                        <path d="M109.503 49.1754H118.002V8.69973H129.313V0.824463H98.1924V8.69973H109.503V49.1754Z"
                            fill="white" />
                        <path
                            d="M142.114 28.7949H161.913V49.1754H170.412H191.575V41.575H170.412V28.6574H184.028V20.9936H170.412V8.42489H191.226V0.824463H170.412H161.913V20.9302H142.114V0.824463H133.626V49.1754H142.114V28.7949Z"
                            fill="white" />
                        <path
                            d="M8.48837 49.1754H16.0465C25.6765 49.1754 31.6702 43.7103 31.6702 35.4333V35.3171C31.6702 30.1796 29.3658 26.4799 25.7611 24.2177C28.7209 21.9661 30.6765 18.8054 30.6765 14.6934V14.5771C30.6765 6.28958 24.6829 0.824463 15.0529 0.824463H0V49.1648H8.48837V49.1754ZM8.48837 8.57288H15.3277C20.148 8.57288 23.0021 10.7399 23.0021 14.7145V14.8202C23.0021 18.5729 20.0423 21.2156 15.3805 21.2156H8.48837V8.57288ZM8.48837 28.3932H16.3848C21.0465 28.3932 24.0063 31.4481 24.0063 35.2008V35.3065C24.0063 39.2811 21.1522 41.4481 16.3319 41.4481H8.48837V28.3932Z"
                            fill="white" />
                        <path
                            d="M217.199 27.4947C222.59 25.5391 226.322 21.2262 226.322 14.6828V14.5665C226.322 6.28958 220.328 0.824463 210.698 0.824463H195.645V49.1648H204.133V28.7209H208.732L219.165 49.1648H228.404L218.911 30.8033L217.199 27.4947ZM211.036 20.9936H204.144V8.56231H210.983C215.804 8.56231 218.658 10.7293 218.658 14.704V14.8097C218.658 18.5623 215.698 20.9936 211.036 20.9936Z"
                            fill="white" />
                        <path
                            d="M274.62 0H273.679L254.736 28.6998L235.782 0H234.842L230.55 49.1966H238.869L241.3 21.9873L254.271 41.6808H255.19L268.161 21.9873L270.592 49.1966H278.911L274.62 0Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_392_6549">
                            <rect width="278.911" height="50" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            
            <div class="LogoGrid">
                <div class="logo1"><img src="@/assets/BIOTHERM-PICTO-01.svg"></div>
                <div class="logo2"><img src="@/assets/BIOTHERM-PICTO-02.svg"></div>
                <div class="logo3"><img src="@/assets/BIOTHERM-PICTO-03.svg"></div>
                <div class="text1">
                    <p class="textLogos" v-html='$t("SCORE_CARD_PAGE.TEXTLOGO1")'></p>
                </div>
                <div class="text2">
                    <p class="textLogos" v-html='$t("SCORE_CARD_PAGE.TEXTLOGO2")'></p>
                </div>
                <div class="text3">
                    <p class="textLogos" v-html='$t("SCORE_CARD_PAGE.TEXTLOGO3")'></p>
                </div>
            </div>


        </div>


        <img :src="imgSrc" v-if="imgSrc != null" class="score-card-img" ref="imageScoreCard">

    </div>
</template>





<script>
import score1 from "@/assets/stars1.svg";
import score2 from "@/assets/stars2.svg";
import score3 from "@/assets/stars3.svg";
// eslint-disable-next-line
import html2canvas from "html2canvas";

export default {
    name: "score-card",
    data() {
        return {
            score: null,
            scoreImageURL: null,
            canvas: null,
            htmlIsVisible: true,
            imgSrc: null
        };
    },
    methods: {
        getScoreImageURL(score) {
            if (score < 2000) {
                return score1;
            } else if (score < 5000) {
                return score2;
            } else {
                return score3;
            }
        },
        async shareScoreImage() {
            let canvasURL = this.imgSrc;
            const blob = await (await fetch(canvasURL)).blob();
            const filesArray = [
                new File(
                    [blob],
                    'score.jpg',
                    {
                        type: blob.type,
                        lastModified: new Date().getTime()
                    }
                )
            ];

            try {
                navigator.share({
                    title: this.$t("SCORE_CARD_PAGE.SHARE_TITLE"),
                    text: this.$t("SCORE_CARD_PAGE.SHARE_TEXT", { score: this.score }),
                    url: 'https://plankton-odyssey.com/',
                    files: filesArray,
                }).then(() => {
                    window.trackEvent("Card Shared")
                });
            } catch (error) {
                alert(this.$t("SCORE_CARD_PAGE.SHARING_NOT_SUPPORTED"));
                console.error("Error sharing:", error);
            }
        },
        async downloadImage() {
            if (this.iOS()) {
                alert(this.$t("SCORE_CARD_PAGE.IOS_PLEASE_LONG_PRESS"));
            }
            else {
                const a = document.createElement('a')
                a.setAttribute('download', 'score.jpg')
                a.setAttribute('href', this.imgSrc)
                a.click()
                a.remove()
            }
        },
        resetContent() {
            const currentScrollY = window.scrollY;
            const elementToExclude = document.getElementById("btnShare");
            window.scrollTo(0, currentScrollY);
            elementToExclude.style.display = "";
            const elementToExclude2 = document.getElementById("btnDownload");
            elementToExclude2.style.display = "";
        },
        iOS() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        },
    },
    mounted() {
        const savedScore = window.localStorage.getItem("score");
        this.score = savedScore ? `${savedScore}` : "No score";
        this.scoreImageURL = this.getScoreImageURL(parseInt(savedScore, 10));

        const currentScrollY = window.scrollY;
        const elementToExclude = document.getElementById("btnShare");
        elementToExclude.style.display = "none";

        // const elementToExclude2 = document.getElementById("btnDownload");
        // elementToExclude2.style.display = "";

        window.scrollTo(0, 0);
        this.$nextTick().then(() => {
            html2canvas(document.getElementById("app"), {
                useCORS: true,
                removeContainer: true,
                backgroundColor: null,
                imageTimeout: 15000, //newline
                scale: 3, //newline
            }).then(_canvas => {
                this.imgSrc = _canvas.toDataURL("image.jpg")
                this.htmlIsVisible = false
                this.resetContent()
            })
        });
    },
};
</script>





<style scoped>
.LogoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 30px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: -80px;
    margin-bottom: 40px;
}

.logo1 {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: center;
    align-self: center;
}



.logo2 {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: center;
    align-self: center;
}

.logo3 {
    grid-area: 1 / 3 / 2 / 4;
    justify-self: center;
    align-self: center;
}

.textLogos {
    text-align: center;
    font-family: "FuturaLTLight";
    color: white;
    font-size: 10px;
}

.text1 {
    grid-area: 2 / 1 / 3 / 2;
    justify-self: center;
    align-self: center;

}

.text2 {
    grid-area: 2 / 2 / 3 / 3;
    justify-self: center;
    align-self: center;

}

.text3 {
    grid-area: 2 / 3 / 3 / 4;
    justify-self: center;
    align-self: center;

}

.page-scorecard {
    position: relative;
    /* overflow: hidden; */
    overscroll-behavior-y: contain;
    min-height: 100vh;
    margin: 0;
    /* padding: 10px; */
}

#score-image {
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#btnShare {
    position: absolute;
    top: 5%;
    right: 7%;
}

#btnDownload {
    position: absolute;
    top: calc(5% + 55px);
    right: 7%;
}

.score-card-img {
    max-width: 100%;
}

#fondScoreCard{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>