import * as PIXI from "pixi.js";
import app from "../App";

import Helper from "../Helper";
import ennemiSprite from "@/assets/game/ennemi.png";

export class Enemy {
    constructor(isSmall) {
        // Constantes pour les déplacements horizontaux et verticaux des ennemis
        this.enemySprite = ennemiSprite;
        this.speed = isSmall ? 0.5 : 1;
        this.amplitudeY = 1;
        this.frequenceY = 0.1;
        this.isSmall = isSmall;
        this.position = null;
    }

    init(position, direction) {
        // Crée un nouveau sprite ennemi à partir de l'image
        this.sprite = PIXI.Sprite.from(this.enemySprite);

        // Fixe le point d'ancrage du sprite au centre
        this.sprite.anchor.set(0.5);

        // Définit la taille du sprite en fonction de la valeur de isSmall
        const size = this.isSmall ? 60 : 120;
        

        this.sprite.width = size;
        this.sprite.height = size;
        if (app.tablet) {
            this.sprite.width *= 1.3
            this.sprite.height *= 1.3
        }

        // Ajoute des propriétés pour identifier les ennemis et les petits ennemis
        this.sprite.isEnemy = true;
        this.sprite.hasCollided = false;

        // Si pas de position, Positionne l'ennemi à l'extérieur de l'écran (gauche ou droite)
        if (!this.isSmall) {
            this.cote = Math.random() > 0.5 ? -1 : 1;
            this.sprite.x = this.cote === 1 ? -this.sprite.width / 2 : app.screen.width + this.sprite.width / 2;
            this.sprite.y = Math.random() * (app.screen.height - this.sprite.height) + this.sprite.height / 2;
        }
        else {
            this.sprite.x = position.x;
            this.sprite.y = position.y;
            this.direction = direction;
        }

        // Ajoute l'ennemi à la scène
        app.gameContainer.addChild(this.sprite);

        // Fonction pour animer l'ennemi
        // Ajoute la fonction d'animation de l'ennemi au ticker
        this.tickerAnimation = (delta) => this.animateMe(delta);
        app.ticker.add(this.tickerAnimation);


        // Rend l'ennemi interactif et réagit au clic
        this.sprite.eventMode = "dynamic"  //.interactive = true;
        this.sprite.buttonMode = true;

        // Associe la fonction de gestion des clics appropriée en fonction de la taille de l'ennemi
        this.sprite.on("pointerdown", () => this.getClicked());
    }

    getClicked() {
        Helper.touched()
        if (app.player.isDead) return;
        app.audioManager.playFX("plasticDestroy");
        this.destroy(false)
    }

    animateMe(delta) {
        if (this.isSmall) {
            // Déplace le petit ennemi en fonction de ses composantes de vitesse
            this.sprite.x += this.direction.x * this.speed * delta;
            this.sprite.y += this.direction.y * this.speed * delta;
            return;
        }

        // Déplace l'ennemi horizontalement et verticalement
        this.sprite.x += this.cote * this.speed * delta;
        this.sprite.y += this.amplitudeY * Math.sin(this.sprite.x * this.frequenceY) * delta;
        // Si l'ennemi sort de l'écran, le supprime de la scène et arrête son animation
        if ((this.cote === 1 && this.sprite.x > app.screen.width + this.sprite.width / 2) || (this.cote === -1 && this.sprite.x < -this.sprite.width / 2)) {
            app.gameContainer.removeChild(this.sprite);
            app.ticker.remove(this.sprite.animate);
        }
    }

    destroy(giveDamageToPlayer = false) {
        // Augmente le score de 10 points
        if (giveDamageToPlayer) {
            app.ui.addScore(-10)
            app.ui.showPointsText(this.sprite.x, this.sprite.y, -10);
            
            app.player.whiteCircle.radius -= 7; // réduire la taille du cercle de 20
            app.player.updateWhiteCircle(app.player.whiteCircle.radius);
        }
        else {
            app.ui.addScore(50)
            app.ui.showPointsText(this.sprite.x, this.sprite.y, 50);
        }

        // Supprime l'ennemi touché de la scène et arrête son animation
        app.gameContainer.removeChild(this.sprite);
        app.ticker.remove((delta) => this.animateMe(delta));
        
        if (!this.isSmall) {
            this.spreadChildren();
        }

        app.ticker.remove(this.tickerAnimation);
        app.gameManager.enemies.splice(app.gameManager.enemies.indexOf(this), 1);
        
        delete this.instance
    }

    spreadChildren() {
        // Crée deux petits ennemis à la position de l'ennemi touché
        // createSmallEnnemi(this.sprite.x, this.sprite.y);
        // createSmallEnnemi(this.sprite.x, this.sprite.y);
    }
}
