import * as PIXI from "pixi.js";
import app from "./App";
let publicPath = process.env.BASE_URL;

import bulleSprite from "@/assets/game/CO2_big_6@2x.png";
import smallBulleSprite from "@/assets/game/CO2_big_6@.png";
import Helper from "./Helper";

export class Bubble {
    constructor() {
        // Remplacer cette partie avec un sprite
        this.sprite = PIXI.Sprite.from(bulleSprite);
        const bubbleWidth = 0.5;

        this.sprite.scale.set(bubbleWidth);
        this.sprite.anchor.set(0.5);

        //taille des bulles
        this.sprite.width = 65;
        this.sprite.height = 65;
        if (app.tablet) {
            this.sprite.width *= 1.3;
            this.sprite.height *= 1.3;
        }

        //vérifier que les bulles ne swpan pas sur le plankton  
        this.position;

        const minDistanceFromPlankton = 200; // ajuster cette valeur en fonction de vos préférences

        this.position = Helper.generateRandomPosition(this.sprite);

        if (Helper.getDistance(this.position.x, this.position.y, app.player.plankton.x, app.player.plankton.y) < minDistanceFromPlankton) {
            this.position.x += Helper.randomIntFromInterval(-1,1)
            this.position.y += Helper.randomIntFromInterval(-1,1)
        }
        
        this.sprite.x = this.position.x;
        this.sprite.y = this.position.y;

        this.speed = Helper.randomFloatFromInterval(0.7,1.1)
        this.tickerAnimation = (delta) => {
            this.sprite.y += delta * this.speed
            if (this.sprite.y > app.view.height) this.destroyInstance();
        }
        
        app.ticker.add(this.tickerAnimation);

        // Rendre le smallCircle interactif et réagir au clic
        this.sprite.eventMode = "dynamic";
        this.sprite.buttonMode = true;

        // Ajouter un écouteur d'événements pour pointerdown
        this.sprite.on('pointerdown', () => {
            Helper.touched()
            if(app.player.isDead) return
            this.dying()
        });

        this.count = Bubble.count
        Bubble.count += 1


        if (!Bubble.bubbleExplositionFrames.length) {
            Bubble.bubbleExplositionFrames = []
            this.bubbleExplositionUrl = `${publicPath}game/bubbleExplosition/fx_destroy.json`;
            PIXI.Assets.load(this.bubbleExplositionUrl).then(() => {
                for (let i = 0; i < 10; i++) {
                    const val = i < 10 ? `0${i}` : i;
                    let animationName = "Composition 1_000";// in the json file
                    Bubble.bubbleExplositionFrames.push(PIXI.Texture.from(`${animationName}${val}.png`));
                }
            })
        }
    }

    static count = 0;
    static bubbleExplositionFrames = [];

    dying() {
        app.audioManager.playFX("bubble" + Helper.randomIntFromInterval(1, 3));
        // Incrémenter le score de x points lorsqu'une bulle est touchée
        app.ui.addScore(10)
        app.ui.showPointsText(this.sprite.x, this.sprite.y, 10);

        // Créer un petit cercle blanc
        this.whiteCircleIndicator = PIXI.Sprite.from(smallBulleSprite);
        this.whiteCircleIndicator.width = 20;
        this.whiteCircleIndicator.height = 20;

        this.whiteCircleIndicator.x = this.sprite.x;
        this.whiteCircleIndicator.y = this.sprite.y;

        app.backgroundContainer.addChild(this.whiteCircleIndicator);

        // Faire disparaître le sprite smallCircle
        app.gameContainer.removeChild(this.sprite);
        app.gameManager.bubbles.splice(app.gameManager.bubbles.indexOf(this), 1);
        // this.sprite.destroy();
        
        this.createExplosition()

        requestAnimationFrame(() => this.animateWhiteCircleIndicator());

        // Augmenter la taille du cercle de vie lorsque l'on tape sur un smallCircle
        if(app.player.whiteCircle.radius + 10 < app.player.maxRadius)
            app.player.whiteCircle.radius += 10; // valeur ajoutée quand on attrape une bulle
        app.player.updateWhiteCircle(app.player.whiteCircle.radius);
    }

    // Animer le cercle blanc vers le plankton
    animateWhiteCircleIndicator() {
        const dx = app.player.plankton.x - this.whiteCircleIndicator.x;
        const dy = app.player.plankton.y - this.whiteCircleIndicator.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance > 7) {
            this.whiteCircleIndicator.x += dx / distance * 15;
            this.whiteCircleIndicator.y += dy / distance * 15;
            requestAnimationFrame(() => this.animateWhiteCircleIndicator());
        } else {
            app.backgroundContainer.removeChild(this.whiteCircleIndicator);
            this.destroyInstance(false);
            app.player.blowOneTime()
        }
    }

    destroyInstance(deleteBubble = true) {
        if (deleteBubble) {
            app.gameManager.bubbles.splice(app.gameManager.bubbles.indexOf(this), 1);
            app.gameContainer.removeChild(this.sprite);
            this.sprite.destroy(false)
        }

        app.ticker.remove(this.tickerAnimation);
        
        delete this.instance
    }

    createExplosition() {
        this.explosion = new PIXI.AnimatedSprite(Bubble.bubbleExplositionFrames);

        this.explosion.anchor.set(0.5);
        this.explosion.x = this.sprite.x;
        this.explosion.y = this.sprite.y;
        this.explosion.width = this.sprite.width;
        this.explosion.height = this.sprite.height;

        this.explosion.animationSpeed = 0.7;

        this.explosion.onFrameChange = function() {
            if (this.currentFrame == 9) {
                app.gameContainer.removeChild(this);
                this.destroy();
            }
        };
        
        app.gameContainer.addChild(this.explosion);
        this.explosion.play();
        
    }
}