// bkg_ambiant.mp3
// bubble_1.mp3
// bubble_2.mp3
// bubble_3.mp3
// game_over.mp3
// plastic_destroy.mp3

import * as PIXI from "pixi.js";
import app from "./App";
// import app from "./App";

const audioUrl = "game/audios/"
let publicPath = process.env.BASE_URL;
import { sound } from '@pixi/sound';

const FXs = [
    {
        url: publicPath + audioUrl + 'bubble_1.mp3',
        title: "bubble1"
    },
    {
        url: publicPath + audioUrl + 'bubble_2.mp3',
        title: "bubble2"
    },
    {
        url: publicPath + audioUrl + 'bubble_3.mp3',
        title: "bubble3"
    },
    {
        url: publicPath + audioUrl + 'game_over.mp3',
        title: "gameover"
    },
    {
        url: publicPath + audioUrl + 'plastic_destroy.mp3',
        title: "plasticDestroy"
    }
]

export class AudioManager {
    constructor() {
        this.loadingMaxCount = FXs.length + 1
        this.loadingStep = 100 / this.loadingMaxCount;
        FXs.forEach(fx => {
            if (sound.exists(fx.title)) {
                this.soundCompleteLoading();
                return
            };
            sound.add(fx.title, {
                url: fx.url,
                preload: true,
                loaded: (err, sound) => {
                    this.soundCompleteLoading();
                }
            });
        });
        
        if (!sound.exists("ambiance")) {
            sound.add("ambiance",  {
                url: publicPath + audioUrl + "bkg_ambiant.mp3",
                preload: true,
                loop: true,
                loaded: (err, sound) => {
                    this.soundCompleteLoading();
                }
            });
        }
        else {
            this.soundCompleteLoading();
        }
    }
    
    soundCompleteLoading() {
        app.addLoadingValueCallback(this.loadingStep)
    }

    skipLoading() {
        // app.skipLoading()
    }

    startAmbiance() {
        sound.play("ambiance");
    }

    /**
     * 
     * @param {*} soundTitle titles: bubble1, bubble2, bubble3, gameover, plasticDestroy
     */
    playFX(soundTitle) {
        sound.play(soundTitle);
    }

    stopAllSounds() {
        FXs.forEach(fx => {
            sound.stop(fx.title);
        });
        sound.stop("ambiance");
    }
}