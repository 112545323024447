import { pwaInfos } from "./registerServiceWorker";

let allVideosSources = [
    {
        name: "home-video-acceuil",
        sources: [
            { path: "/video/webm/470x470_BIOTHERM_SCIENCE_LIFE_PLANKTON_FINAL-VIDEO.webm", type: "video/webm" },
            { path: "/video/470x470_BIOTHERM_SCIENCE_LIFE_PLANKTON_FINAL-VIDEO-LR.mov", type: "video/mp4" }
        ]
    },
    {
        name: "campagne-video",
        sources: [
            { path: "/video/BT_brandcampaign2022_oceanpositive_hero_169_VI_Logo-web3.mp4", type: "video/mp4" }
        ]
    },
]


export function checkVideosForPWA() {
    for (let i = 0; i < allVideosSources.length; i++) {
        const video = allVideosSources[i];

        
        for (let i = 0; i < video.sources.length; i++) {
            const path = video.sources[i].path;
            const storedVideoTitle = video.name + "-" + i;
            
            // first check if blob exist
        
            const DBRequest = window.indexedDB.open('plankton', 1);

            DBRequest.onsuccess = event => {
                const db = event.target.result;
                const transaction = db.transaction(['videos']);
                const objectStore = transaction.objectStore('videos');
                const videoStored = objectStore.get(storedVideoTitle);

                videoStored.transaction.oncomplete = event => {
                    if (!videoStored.result) {
                        fetchVideo(path, storedVideoTitle);
                    }
                };
            }

            DBRequest.onupgradeneeded = event => {
                console.log('onupgradeneeded =')
                const db = event.target.result;
                db.createObjectStore('videos', { keyPath: 'name' });
            }
        }
    }
}

function fetchVideo(path, storedVideoTitle) {
    const videoRequest = fetch(window.location.origin + path)
                .then(response => {
                    return response.blob()
                })
                .catch(error => {
                    console.log('error =', error)
                });

    videoRequest.then(blob => {
        const request = window.indexedDB.open('plankton', 1);

        request.onsuccess = event => {
            const db = event.target.result;

            const transaction = db.transaction(['videos']);
            const objectStore = transaction.objectStore('videos');

            const videoStored = objectStore.get(storedVideoTitle);

            videoStored.transaction.oncomplete = event => {
                if (!videoStored.result) {
                    const videoObjectStore = db.transaction('videos', 'readwrite').objectStore('videos');
                    videoObjectStore.add({ name: storedVideoTitle, blob: blob });
                    // alert("ok cool")
                }
            };
        }
    });
}