import * as PIXI from "pixi.js";
import app from "./App";
let publicPath = process.env.BASE_URL;

import scoreBackgroundTexture from "@/assets/game/ombreScore.png";
import vignetteBackgroundTexture from "@/assets/game/vignette-texture.png";
import vignetteWhiteBackgroundTexture from "@/assets/game/vignetteWhite-texture.png";

import Helper from "./Helper";

let levelUpUrl = `${publicPath}game/LevelUp/levelup.json`;


export class UI {
    constructor(_$t, destroyGame, vueRouter) {
        this.$t = _$t;
        this.vueRouter = vueRouter

        this.score = 0;
        this.initBackground();
        this.initScore();
        this.initLevelUp();

        this.destroyGame = destroyGame; 
    }

    initLevelUp() {
        this.levelUp = {}
        this.levelUp.frames = []
        this.levelUp.container = new PIXI.Container();
        this.levelUp.container.alpha = 0;
        app.uiContainer.addChild(this.levelUp.container);

        this.levelUp.vignetteBackground = PIXI.Sprite.from(vignetteWhiteBackgroundTexture);
        this.levelUp.vignetteBackground.width = app.view.width/2
        this.levelUp.vignetteBackground.height = app.view.height/2
        this.levelUp.container.addChild(this.levelUp.vignetteBackground)

        PIXI.Assets.load(levelUpUrl).then(() => {
            for (let i = 0; i < 35; i++) {
                const val = i < 10 ? `0${i}` : i;
                let animationName = "message-lvlup-4";// in the json file
                this.levelUp.frames.push(PIXI.Texture.from(`${animationName}-${val}.png`));
            }

            this.levelUp.mainSprite = new PIXI.AnimatedSprite(this.levelUp.frames);
    
            this.levelUp.mainSprite.anchor.set(0.5);
            this.levelUp.mainSprite.x = app.view.width/4
            this.levelUp.mainSprite.y = app.view.height / 4 + 70

            this.levelUp.container.addChild(this.levelUp.mainSprite);
        })
    }

    displayLevelUp() {
        this.levelUp.mainSprite.play();
        this.levelUp.container.alpha = 1;
        this.levelUp.coroutine = () => {
            this.fadeOutLevelUp()
        }
        app.ticker.add(this.levelUp.coroutine)
    }

    fadeOutLevelUp() {
        this.levelUp.container.alpha -= 0.015;
        if (this.levelUp.container.alpha <= 0) {
            this.levelUp.container.alpha = 0; 
            this.levelUp.mainSprite.stop();
            app.ticker.remove(this.levelUp.coroutine)
        }
    }

    initBackground() {
        // dynamic background
        this.vignetteBackground = {}
        this.vignetteBackground.opacity = 0
        this.vignetteBackground.tempOpacity = 0
        this.vignetteBackground.sprite = PIXI.Sprite.from(vignetteBackgroundTexture);
        this.vignetteBackground.sprite.x = 0
        this.vignetteBackground.sprite.y = 0
        this.vignetteBackground.sprite.width = app.view.width/2
        this.vignetteBackground.sprite.height = app.view.height/2
        this.updateVignetteBackground()

        app.uiContainer.addChild(this.vignetteBackground.sprite);

        app.ticker.add((time) => {
            let distanceBetween = Math.abs(this.vignetteBackground.opacity - this.vignetteBackground.tempOpacity)
            if (distanceBetween > 0.01) {
                this.vignetteBackground.tempOpacity = Helper.lerp(
                    this.vignetteBackground.tempOpacity,
                    this.vignetteBackground.opacity,
                    0.08)
                this.updateVignetteBackground()
            }
        })
    }

    updateVignetteBackground() {
        this.vignetteBackground.sprite.alpha  = this.vignetteBackground.tempOpacity
    }

    initScore() {
        this.scoreBackground = PIXI.Sprite.from(scoreBackgroundTexture);
        this.scoreBackground.anchor.set(0.5);
        this.scoreBackground.x = 55;
        this.scoreBackground.y = 50;

        this.scoreTitre = new PIXI.Text(this.$t("IN_GAME.SCORE"), {
            fontFamily: 'FuturaLTMedium',
            fontSize: 12,
            fill: 0xffffff
        });

        this.scoreTitre.x = 10;
        this.scoreTitre.y = 20;

        app.uiContainer.addChild(this.scoreBackground);

        app.uiContainer.addChild(this.scoreTitre);


        this.scoreText = new PIXI.Text(`${this.score}`, {
            fontFamily: 'FuturaLTMedium',
            fontSize: 32,
            fill: 0xffffff
        });

        this.scoreText.x = 10;
        this.scoreText.y = 35;
        app.uiContainer.addChild(this.scoreText);
    }

    addScore(points) {
        if (this.score + points < 0) {
            this.score = 0;
            this.scoreText.text = `${this.score}`;
            return;
        }
        this.score += points;
        this.scoreText.text = `${this.score}`;
    }

    // Fonction pour afficher le texte des points à une position spécifique (x, y)
    showPointsText(x, y, points) {
        const pointsText = new PIXI.Text(`${points}`, {
            fontFamily: 'FuturaLTMedium',
            fontSize: 16,
            fill: 0xffffff
        });

        pointsText.x = x;
        pointsText.y = y;

        app.uiContainer.addChild(pointsText);

        setTimeout(() => {
            if (app && app.stage) {
                app.uiContainer.removeChild(pointsText);
            }
        }, 1200);
    }

    displayGameOver() {
        //overlay
        const overlay = new PIXI.Graphics();
        overlay.beginFill(0x000000, 0.5);
        overlay.drawRect(0, 0, app.view.width, app.view.height);
        overlay.endFill();

        app.uiContainer.addChild(overlay);


        //fond du bouton 
        const buttonBackground = new PIXI.Graphics();
        buttonBackground.beginFill(0x02587C); // Couleur du fond
        buttonBackground.drawRoundedRect(0, 0, 210, 60, 30);
        buttonBackground.endFill();

        // Texte du bouton

        const buttonText = new PIXI.Text(this.$t("IN_GAME.GAME_OVER_MESSAGE"), {
            fontFamily: 'FuturaLTLight',
            fontSize: 18,
            fill: 0xffffff,
            align: "center"
        });

        buttonText.anchor.set(0.5, 0.5);
        buttonText.x = buttonBackground.width / 2;
        buttonText.y = buttonBackground.height / 2;

        // Créez un PIXI.Container pour contenir le fond et le texte du bouton
        const shareButton = new PIXI.Container();
        shareButton.addChild(buttonBackground);
        shareButton.addChild(buttonText);

        shareButton.x = app.view.width / 4 - shareButton.width / 2;
        shareButton.y = app.view.height / 4 - shareButton.height / 2;
        shareButton.eventMode = "dynamic"  //.interactive = true;
        shareButton.buttonMode = true;

        app.uiContainer.addChild(shareButton);

        shareButton.on("pointerdown", () => {
            // Enregistrez le score et l'afficher dans une nouvelle page
            window.localStorage.setItem("score", app.ui.score);

            this.vueRouter.push({
                name: "scorePage"
            })
        });

    }
}