import * as PIXI from "pixi.js";
let publicPath = process.env.BASE_URL;

import app from "./App";
import Helper from "./Helper";
import { PlopParticule } from "./PlopParticule";
let StartedAnimationFunction;

let planktons = [
    "diatom", 
    "coco", 
    "dino"
]

export class Player {
    constructor() {
        this.isDead = false;
        this.selectedPlankton = 0;

        this.plankton = {
            x: 0,
            y: 0
        }
        
        app.ticker.add(() => {
            if (!this.startedAnimationIsOver) return;
            this.detectCollisions()
            this.displayLifeDangerTint()
        });

        this.touchedEnemy = false;
        this.touchedEnemyIntervalLimit = 1000;
        this.blowingCircles = [];
        this.touchable = false;
        this.startedTargetPosition = app.view.height / 2 - 120 - 2;
        
        this.startedAnimationIsOver = false;
        
    }

    updatePlayerPosition() {
        // starting position animation
        if (!this.plankton) return;
        
        let distanceBetween = Math.abs(this.plankton.y - (this.startedTargetPosition))
        
        if (distanceBetween > 2) {
            this.plankton.y = Helper.lerp(
                this.plankton.y,
                this.startedTargetPosition,
                0.05)
            return true;
        }
        return false;

    }

    initPlanktonSprites() {
        return new Promise(resolve => {
            if (window.localStorage.getItem("planktonIndex")) {
                this.selectedPlankton = parseInt(window.localStorage.getItem("planktonIndex"));
            }


            this.animPlanktonUrl = `${publicPath}game/planktons/${planktons[this.selectedPlankton]}.json`;
                
            PIXI.Assets.load(this.animPlanktonUrl).then(() => {

                // create an array of textures from an image path
                const frames = [];

                for (let i = 0; i < 60; i++) {
                    const val = i < 10 ? `0${i}` : i;
                    let animationName = planktons[this.selectedPlankton];// in the json file
                    frames.push(PIXI.Texture.from(`${animationName}-${val}.png`));
                }

                this.plankton = new PIXI.AnimatedSprite(frames);

                this.plankton.tint = 0xe6effc;
                this.plankton.anchor.set(0.5);
                this.plankton.x = app.screen.width / 2;
                this.plankton.y = app.screen.height + 125;

                this.plankton.width = 120;
                this.plankton.height = 120;

                this.plankton.animationSpeed = 0.8;
                this.plankton.loop = true;
                this.plankton.play();
                this.plankton.eventMode = "dynamic"
                this.plankton.dragging = false;

                this.plankton.on("pointerdown", (event) => {
                    this.plankton.dragging = true;
                    this.plankton.pointerId = event.data.pointerId;
                    Helper.touched()
                });

                app.stage.on("pointermove", (event) => this.onPointerMove(event));

                this.plankton.on("pointerup", () => {
                    this.plankton.dragging = false;
                });

                app.uperGameContainer.addChild(this.plankton);

                resolve();
            });
        })
    }

    displayPlayer() {
        StartedAnimationFunction = () => {
            if (!this.updatePlayerPosition()) {
                app.gameManager.startSpawningBubbles();
                app.gameManager.startSpawningEnemies();
                this.touchable = true;
                
                this.initLifeCircle();

                this.startedAnimationIsOver = true;
                app.ticker.add(() => {
                    PlopParticule.updateAllPlops()
                })

                app.ticker.remove(StartedAnimationFunction)
            }
        }
        
        app.ticker.add(StartedAnimationFunction);
    }

    initLifeCircle() {
        this.maxRadius = 130; // taille maximale du cercle de vie

        this.whiteCircle = new PIXI.Graphics();

        this.whiteCircle.lineStyle(2.5, 0xffffff);
        this.whiteCircle.drawCircle(0, 0, 85);
        this.whiteCircle.endFill();

        this.whiteCircle.x = this.plankton.x;
        this.whiteCircle.y = this.plankton.y;

        app.uperGameContainer.addChild(this.whiteCircle);
    
        // le cercle de vie réduit avec le temps
        const totalTime = 10; // durée totale
        
        
        const initialRadius = 95; // taille du cercle de vie au départ
        this.decreasePerFrame = initialRadius / (totalTime * 60);
        
        if (app.tablet) {
            this.decreasePerFrame *= 1.5
        }

        this.whiteCircle.radius = initialRadius;

        
        app.ticker.add(() => this.updateCircles());
    }

    updateCircles() {
        if (this.whiteCircle.radius > 20) {
            this.whiteCircle.radius -= this.decreasePerFrame;
            this.updateWhiteCircle(this.whiteCircle.radius);
        } else {
            app.gameOver();
            this.isDead = true;
        }

        this.blowingCircles.forEach(circle => {
            circle.x = this.plankton.x;
            circle.y = this.plankton.y;
            circle.radius += 1;
            circle.alpha -= 0.08;
            
            this.upadteBlowingCircle(circle )
            
            if (circle.radius > circle.radiusLimit) {
                app.gameContainer.removeChild(circle);
                this.blowingCircles.splice(this.blowingCircles.indexOf(circle), 1);
            }
        });
    }
    

    blowOneTime() {
        let circle = new PIXI.Graphics();
        // this.maxRadius = 130; // taille maximale du cercle de vie
        if (this.whiteCircle.radius < this.maxRadius) {
            circle.radius = this.whiteCircle.radius;
        }
        else {
            circle.radius = this.maxRadius;
        }
        circle.radiusLimit = circle.radius + 15;
        circle.alpha = 1;

        this.upadteBlowingCircle(circle)

        circle.x = this.plankton.x;
        circle.y = this.plankton.y;

        app.gameContainer.addChild(circle);
        this.blowingCircles.push(circle)
    }

    upadteBlowingCircle(circle) {
        circle.clear();
        circle.lineStyle(2.5, 0xffffff, circle.alpha);
        circle.drawCircle(0, 0, circle.radius);
        circle.endFill();
    }
    
    updateWhiteCircle(radius) {
        // Vérifier si le rayon est supérieur à la taille maximale
        if (radius > this.maxRadius) {
          radius = this.maxRadius;
        }
  
        this.whiteCircle.clear();
        this.whiteCircle.lineStyle(2, 0xe8e7e6);
        this.whiteCircle.drawCircle(0, 0, radius);
        this.whiteCircle.endFill();
    }

    timerForPloping=0
    onPointerMove(event) {
        if (this.touchable) {
            if (this.plankton.dragging && this.plankton.pointerId == event.data.pointerId) {
                const newPosition = event.data.getLocalPosition(app.stage);
                this.plankton.x = this.whiteCircle.x = newPosition.x;
                this.plankton.y = this.whiteCircle.y = newPosition.y;
                this.timerForPloping++;
                if (this.timerForPloping % 5 == 0) new PlopParticule()
                if (this.timerForPloping % 20 == 0) Helper.touched()
            }
        }
    }

    //le plankton vibre quand il touche un ennemi
    shakePlankton(duration) {
        let startTime = Date.now();
        this.plankton.tint = 0xfc3838; // Teinter le plankton en rouge

        const shake = (delta) => {
            const elapsedTime = Date.now() - startTime;

            if (elapsedTime < duration) {
                this.plankton.x += (Math.random() * 4 - 2) * delta;
                this.plankton.y += (Math.random() * 4 - 2) * delta;

                // Modifier l'opacité en fonction du temps écoulé
                this.plankton.alpha = 0.6 + 0.6 * Math.sin((elapsedTime / duration) * Math.PI);
            } else {
                this.plankton.alpha = 100; // Rétablir l'opacité à 100%
                this.plankton.tint = 0xe6effc; // rétablir la teinte
                app.ticker.remove(shake);
            }
        };

        app.ticker.add(shake);
    }

    startingLimitAlert = 50
    displayLifeDangerTint() {
        if (this.whiteCircle.radius > this.startingLimitAlert) {
            this.plankton.tint = `rgb(255, 255,255)`;
            return
        };
        
        let radiusInvertValue = this.startingLimitAlert - this.whiteCircle.radius;
        let redAndGreen = Math.floor(Helper.remap(radiusInvertValue, 0, this.startingLimitAlert - 20, 255, 40));
        this.plankton.tint = `rgb(255, ${redAndGreen}, ${redAndGreen})`;
    }

    loopdelay = 0
    detectCollisions() { 
        if (!app.gameManager || this.isDead) return;

        if (this.loopdelay % 6 == 0) {
            app.gameManager.bubbles.forEach((bubble) => {
                if (Helper.detectCollision(app.player.plankton, bubble.sprite)) {
                    bubble.dying()
                    this.blowOneTime()
                }
            });
        }

        
        if (this.loopdelay % 6 == 4) {
            app.gameManager.enemies.forEach((enemy) => {
                
                    app.gameManager.bubbles.forEach((bubble) => {
                        if (Helper.detectCollision(bubble.sprite, enemy.sprite)) {
                            bubble.createExplosition();
                            bubble.destroyInstance();
                        }
                    }) 
                
                // if touched dont check collision with enemy
                if (this.touchedEnemy) return;
                if (Helper.detectCollision(app.player.plankton, enemy.sprite)) {
                    enemy.destroy(true)
                    this.shakePlankton(240); 
                    this.touchedEnemy = true;

                    setTimeout(() => {
                        this.touchedEnemy = false;
                    }, this.touchedEnemyIntervalLimit);
                }
            })
        }


        this.loopdelay++;
    }
}