import * as PIXI from "pixi.js";
import app from "./App";

import Helper from "./Helper";

export class PlopParticule {
    constructor() {
        this.sprite = new PIXI.Graphics();
        // this.maxRadius = 130; // taille maximale du cercle de vie
        let spiteWidth = app.player.plankton.width * 0.7
        this.sprite.radius = Helper.randomIntFromInterval(spiteWidth-100, spiteWidth-40);

        this.sprite.alpha = 0.6;
        this.sprite.radiusLimit = this.sprite.radius + 30;

        this.updatePlop()

        this.sprite.x = Helper.randomIntFromInterval(app.player.plankton.x-20, app.player.plankton.x+20);
        this.sprite.y = Helper.randomIntFromInterval(app.player.plankton.y - 20, app.player.plankton.y + 20);
        
        app.backgroundContainer.addChild(this.sprite);
        PlopParticule.plops.push(this)
    }

    static plops = [];
    
    static updateAllPlops() {
        this.plops.forEach(plop => plop.updatePlop());
    }

    updatePlop() {
        this.sprite.radius += 0.5;
        this.sprite.alpha -= 0.01;

        if (this.sprite.radius > this.sprite.radiusLimit) {
            app.backgroundContainer.removeChild(this);
            PlopParticule.plops.splice(PlopParticule.plops.indexOf(this), 1);
            this.sprite.destroy(true);
        }
        else {
            this.sprite.clear();
            this.sprite.lineStyle(3, 0xffffff, this.sprite.alpha);
            this.sprite.drawCircle(0, 0, this.sprite.radius);
            this.sprite.endFill();
        }

    }
}