import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Accueil from "../components/Accueil.vue";
import chooseplankton from "../components/chooseplankton.vue";
import Loading from "../components/loading.vue";
import tuto from "../components/tuto.vue";
import game from "../components/game.vue";
import score from "../components/score.vue";
import scorecard from "../components/scorecard.vue";
import { messages } from "@/assets/localization";
import { i18n, vueApp } from "@/main";
import Helper from "@/game/Helper";

const routes = [
	{ path: "/", component: Accueil },
	{ path: "/chooseplankton", component: chooseplankton },
	{ path: "/loading", component: Loading },
	{ path: "/tuto", component: tuto },
	{ path: "/game", component: game },
	{ path: "/score", name: "scorePage", component: score },
	{ path: "/scorecard", component: scorecard },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

function hasQueryParams(route) {
	return !!Object.keys(route.query).length
}
function checkLang(lang) {
	if (!lang) return false;
	if (Object.keys(messages).includes(lang)) {
		if (i18n.global.locale != lang) {
			i18n.global.locale = lang
			
			window.trackEvent("Localization", {
				props: {
					lang: lang
				}
			})
		}
	}
}

router.beforeEach((to, from, next) => {
	if (!from.query.retail) delete from.query.retail
	else if (from.query.retail == 1) {
		window.trackEvent("Retail")
	}
	if (!from.query.lang) delete from.query.lang

	checkLang(from.query.lang)
	checkLang(to.query.lang)
	if (to.query.retail == 1) {
		Helper.initRetailMode()
	}
	if (!hasQueryParams(to) && hasQueryParams(from)) {
		next({path: to.path, query: from.query});
	} else {
		next()
	}
})

export default router;
