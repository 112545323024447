import * as PIXI from "pixi.js";
import app from "./../App";

import Helper from "./../Helper";
import { Enemy } from "./Enemy";
import ennemiSprite from "@/assets/game/MICROPLASTIC/microplastic_3_1_2x.png";

export class YellowPlastic extends Enemy {
    constructor(isSmall, position, direction) {
        super(isSmall);
        
        this.enemySprite = ennemiSprite;

        this.init(position, direction)
    }

    spreadChildren() {
        super.spreadChildren();
        app.gameManager.addNewEnemy(0, true, {x: this.sprite.x, y: this.sprite.y}, {x: 0, y: -1})
        app.gameManager.addNewEnemy(0, true, {x: this.sprite.x, y: this.sprite.y}, {x: -1, y: 1})
        app.gameManager.addNewEnemy(0, true, {x: this.sprite.x, y: this.sprite.y}, {x: 1, y: 1})
    }
}
