<template>
    <div class="page-tuto">

        <section>
            <h1 id="howto">{{ $t("TUTO_PAGE.TITLE") }}</h1>

            <!-- Swiper -->
            <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img id="imgTutoMobile" src="@/assets/tuto01.png" />
                        <img id="imgTutoiPad" src="@/assets/tutoiPad01.png" />
                        <p class="txtTuto">
                            {{ $t("TUTO_PAGE.TUTO1") }}
                        </p>
                    </div>

                    <div class="swiper-slide">
                        <img id="imgTutoMobile" src="@/assets/tuto02.png" />
                        <img id="imgTutoiPad" src="@/assets/tutoiPad02.png" />
                        <p class="txtTuto">
                            {{ $t("TUTO_PAGE.TUTO2") }}
                        </p>
                    </div>

                    <div class="swiper-slide">
                        <img id="imgTutoMobile" src="@/assets/tuto03.png" />
                        <img id="imgTutoiPad" src="@/assets/tutoiPad03.png" />
                        <p class="txtTuto">
                            {{ $t("TUTO_PAGE.TUTO3") }}
                        </p>
                    </div>
                </div>

                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>

            </div>



        </section>

        <div class="tutoBottomGrid">
            <div class="tutoPagination">
                <div class="swiper-pagination"></div>
            </div>
            <div class="tutoButton">
                <a href="#" class="continueButton"> {{ $t("TUTO_PAGE.BOUTON") }}</a>
            </div>

            <div class="tutoSkip">
                <router-link to="/game" id="skipTxt">{{ $t("TUTO_PAGE.SKIP") }}</router-link>
            </div>
        </div>
    </div>
</template>




<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export default {
    name: "pageTuto",
    data() {
        return {
            swiper: null,
            continueButton: null
        };
    },
    mounted() {
        this.$root.showNav = false;
        this.initSwiper();
    },
    beforeUnmount() {
        this.$root.showNav = true;
        this.continueButton.removeEventListener("click", this.handleContinueButtonClick);
        this.swiper.off("slideChange", this.handleSlideChange);
    },
    methods: {
        initSwiper() {
            this.swiper = new Swiper(".mySwiper", {
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });

            this.continueButton = document.querySelector(".continueButton");
            this.continueButton.addEventListener("click", this.handleContinueButtonClick);

            this.swiper.on("slideChange", this.handleSlideChange);
        },
        handleContinueButtonClick(event) {
            event.preventDefault();
            if (this.swiper.activeIndex == this.swiper.slides.length - 1) {
                this.$router.push({ path: "/game" }); // Redirige vers la page du jeu
            } else {
                this.swiper.slideNext(); // passer à la slide suivante
            }
        },
        handleSlideChange() {
            if (this.swiper.activeIndex === 2) {
                // quand on est sur la 3e slide
                this.continueButton.textContent = "LET'S GO!";
            } else {
                this.continueButton.textContent = "CONTINUE";
            }
        }
    }
};
</script>


<style scoped>
/* iPhone SE */
@media only screen and (max-width: 380px) {
    section {
        padding-top: 0;
    }

    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1.6px;
        text-shadow: none;
    }

    .swiper {
        width: 85%;
        height: 60vh;
    }

    .swiper-slide img {
        margin: auto;
        display: block;
        width: 80%;
        height: 80%;
        -o-object-fit: cover;
        object-fit: cover;
        margin-top: 20px;
    }

    .txtTuto {
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0;
        margin-top: -12px;
    }

    .continueButton {
        /* width: 90px; */
        text-align: center;
        background-color: #00263A;
        border-radius: 42px;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: "FuturaLTLight";
        letter-spacing: 0.6px;
        font-size: 10px;
        padding: 10px 18px;
        text-decoration: none;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-next {
        color: white;
        padding-left: 20px;
    }

    .swiper-button-prev {
        color: white;
    }
}

/* iPhone */
@media only screen and (max-width: 600px) and (min-width: 381px) {
    .swiper-button-next {
        display: none;
    }

    .swiper-button-prev {
        display: none;
    }
}


.page-tuto {
    background: url(~@/assets/fond-tuto.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
}

#imgTutoMobile {
    display: block;
}

#imgTutoiPad {
    display: none;
}

@media screen and (min-width: 40em) {
    .swiper-slide img {
        width: 90%;
        height: 90%;
    }


    #imgTutoMobile {
        display: none;
    }

    #imgTutoiPad {
        display: block;
    }

    .tutoBottomGrid {
        grid-template-rows: 68px;
        grid-row-gap: 39px;
    }

    .swiper {
        height: 54vh;
    }

    .swiper-pagination-bullets {
        padding-top: 50px !important;
    }

    .swiper-button-next {
        display: none;
    }

    .swiper-button-prev {
        display: none;
    }


}
</style>