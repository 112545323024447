import en from "./langages/en.json"
import de from "./langages/de.json"
import es from "./langages/es.json"
import it from "./langages/it.json"
import zh from "./langages/zh.json"

export let messages = {
    en: en,
    de: de,
    es: es,
    it: it,
    zh: zh
}

