import MobileDetect from "mobile-detect";
import app from "./App";
import router from "@/router";
    var md = new MobileDetect(window.navigator.userAgent);

export default {
    generateRandomPosition(smallCircle) {
        const x = this.randomRange(app.gameManager.marginLeft + (smallCircle.width / 2), (app.view.width/2) - (smallCircle.width / 2) - app.gameManager.marginLeft);
        const y = this.randomRange(app.gameManager.marginTop + (smallCircle.height / 2), ((app.view.height/1.7) * app.gameManager.spawnAreaHeightPercentage) - (smallCircle.height / 2));
        return { x, y };
    },
    
    isDistanceGreaterThanMin(x1, y1, x2, y2, minDistance) {
        const dx = x1 - x2;
        const dy = y1 - y2;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance > minDistance;
    }, 
    randomRange(min, max) {
        return Math.random() * (max - min) + min;
    },
    detectCollision(sprite1, sprite2) {
        const dx = sprite1.x - sprite2.x;
        const dy = sprite1.y - sprite2.y;
        const distance = Math.sqrt((dx) * (dx) + dy * dy);
        const radiusSum = (sprite1.width * 0.4) + (sprite2.width * 0.4);
  
        return distance < radiusSum;
    },
    lerp ( start, end, amt ) {
        // return a + ratio * (b - a);
        return (1-amt)*start+amt*end
    },
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    },
    randomFloatFromInterval(min, max) { // min and max included 
        return Math.random() * (max - min + 1) + min;
    },
    getDistance(x1, y1, x2, y2) {
        let y = x2 - x1;
        let x = y2 - y1;
      
        return Math.sqrt(x * x + y * y);
    },
    logInHtml(message, removePrevious = false) {
        if (!document.querySelector("#debug")) return;
        if (removePrevious) {
            document.querySelector("#debug").innerHTML = message;
            return;
        }
        document.querySelector("#debug").innerHTML += message;
    },
    rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    },
    remap(target, in_min, in_max, out_min, out_max) {
        return (target - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    },
    scaleOrderFunction(a, b) {
        return b - a;
    },
    defineDefaultScores() {
		// let defaultScores = [500, 100, 800, 570, 200, 850, 500, 101, 802, 572, 202, 851 ]
		// let defaultScores = [700, 50, 1470]
		let defaultScores = []
		window.localStorage.setItem("allScores", JSON.stringify(defaultScores));
    },
    mobileIsTablet() {
        var md = new MobileDetect(window.navigator.userAgent);
        return !md.mobile() ? true : false;
    },
    isSafari() {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        return isSafari;
    }, 

    touchedOnBodyInitiated: false, 
    touched() {
        // if untouch few moment go back home 
        this.initTouchTimeOut();
    },
    initRetailMode() {
        this.initTouchTimeOut();
        if (!this.touchedOnBodyInitiated) {
            document.body.addEventListener("click", () => {
                this.touched()
            })
            this.touchedOnBodyInitiated = true;
        }
    },
    initTouchTimeOut() {
        if (this.timeOut) clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
            router.push({path: "/"})
        }, 45000);
    }

}

const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}
  