<template>
  <div id="app">
    <nav v-if="showNav">
      <router-link to="/">{{ $t("NAV.HOME") }} </router-link>
      <router-link to="/chooseplankton">{{ $t("NAV.CHOOSE") }} </router-link>
      <router-link to="/loading">{{ $t("NAV.LOADING") }} </router-link>
      <router-link to="/tuto">{{ $t("NAV.TUTO") }}</router-link>
      <router-link to="/game">{{ $t("NAV.GAME") }}</router-link>
      <router-link to="/score">{{ $t("NAV.SCORE") }}</router-link>
      <router-link to="/scorecard">{{ $t("NAV.SCORE_CARD") }}</router-link>
    </nav>
    <router-view />
  </div>


  <section id="landscapePage">
    <div id="LandscapeOverlay">
      <div class="LandscapeOverlay1">
        <h1 v-html='$t("ORIENTATION_PAGE.TITLE")'></h1>
        <!-- <h1>CHANGE YOUR SCREEN ORIENTATION</h1> -->
      </div>
      <div class="LandscapeOverlay2">
        <svg width="78" height="79" viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_392_5945)">
            <path
              d="M53.625 73.625L9.75 73.625L9.75 44.375L34.125 44.375L34.125 39.5L4.875 39.5C2.1833 39.5 -1.60931e-06 41.6833 -1.49165e-06 44.375L-2.13093e-07 73.625C-9.54352e-08 76.3167 2.1833 78.5 4.875 78.5L58.5 78.5C61.1917 78.5 63.375 76.3167 63.375 73.625L63.375 68.75L53.625 68.75L53.625 73.625Z"
              fill="white" />
            <path
              d="M39 5.375L39 59C39 61.6917 41.1833 63.875 43.875 63.875L73.125 63.875C75.8167 63.875 78 61.6917 78 59L78 5.37499C78 2.6833 75.8167 0.499995 73.125 0.499995L43.875 0.499996C41.1833 0.499996 39 2.6833 39 5.375ZM73.125 10.25L73.125 54.125L43.875 54.125L43.875 10.25L73.125 10.25Z"
              fill="white" />
            <path
              d="M24.375 10.25C13.6221 10.25 4.875 18.9971 4.875 29.75L9.75 29.75C9.75 21.6854 16.3104 15.125 24.375 15.125L24.375 22.4375L34.125 12.6875L24.375 2.9375L24.375 10.25Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_392_5945">
              <rect width="78" height="78" fill="white" transform="translate(0 78.5) rotate(-90)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="LandscapeOverlay3">
        <p class="intro" v-html='$t("ORIENTATION_PAGE.INFO")'></p>
        <!-- <p class="intro">Rotate your device to enjoy<br>
          Life Plankton Odyssey</p> -->
      </div>
      <div class="LandscapeOverlay4">
        <svg width="67" height="12" viewBox="0 0 67 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_392_5952)">
            <path d="M10.6959 0.205505H8.65869V11.8072H10.6959V0.205505Z" fill="white" />
            <path
              d="M23.8858 5.96703C23.8858 2.70191 21.3996 7.62939e-06 17.8376 7.62939e-06C14.2757 7.62939e-06 11.7539 2.73489 11.7539 6.00001V6.03299C11.7539 9.2981 14.2402 12 17.8046 12C21.3691 12 23.8858 9.26512 23.8858 6.00001V5.96703ZM21.7471 6.03299C21.7471 8.28838 20.1387 10.1099 17.8351 10.1099C15.5315 10.1099 13.8901 8.25286 13.8901 6.00001V5.96703C13.8901 3.71164 15.4985 1.89007 17.8021 1.89007C20.1057 1.89007 21.7471 3.74715 21.7471 6.00001V6.03299Z"
              fill="white" />
            <path d="M26.2807 11.8021H28.3205V2.08795H31.0351V0.197891H23.5662V2.08795H26.2807V11.8021Z" fill="white" />
            <path
              d="M34.1075 6.91079H38.8593V11.8021H40.8991H45.9781V9.97802H40.8991V6.87781H44.1667V5.03848H40.8991V2.02199H45.8944V0.197891H40.8991H38.8593V5.02326H34.1075V0.197891H32.0703V11.8021H34.1075V6.91079Z"
              fill="white" />
            <path
              d="M2.03721 11.8021H3.85116C6.16237 11.8021 7.60085 10.4905 7.60085 8.50402V8.47612C7.60085 7.24313 7.04778 6.35518 6.18266 5.81227C6.89302 5.27189 7.36237 4.51332 7.36237 3.52643V3.49853C7.36237 1.50952 5.92389 0.197891 3.61268 0.197891H0V11.7996H2.03721V11.8021ZM2.03721 2.05751H3.67865C4.83552 2.05751 5.52051 2.5776 5.52051 3.53151V3.55688C5.52051 4.45751 4.81015 5.09176 3.69133 5.09176H2.03721V2.05751ZM2.03721 6.81438H3.93235C5.05116 6.81438 5.76152 7.54757 5.76152 8.44821V8.47358C5.76152 9.42749 5.07653 9.94757 3.91966 9.94757H2.03721V6.81438Z"
              fill="white" />
            <path
              d="M52.1278 6.59874C53.4216 6.12939 54.3172 5.0943 54.3172 3.5239V3.49599C54.3172 1.50952 52.8787 0.197891 50.5675 0.197891H46.9548V11.7996H48.992V6.89303H50.0956L52.5997 11.7996H54.817L52.5388 7.39282L52.1278 6.59874ZM50.6487 5.03848H48.9946V2.05497H50.636C51.7929 2.05497 52.4779 2.57506 52.4779 3.52897V3.55434C52.4779 4.45497 51.7675 5.03848 50.6487 5.03848Z"
              fill="white" />
            <path
              d="M65.9088 7.62939e-06H65.683L61.1367 6.88796L56.5878 7.62939e-06H56.3621L55.332 11.8072H57.3286L57.9122 5.27696L61.0251 10.0034H61.2458L64.3587 5.27696L64.9422 11.8072H66.9388L65.9088 7.62939e-06Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_392_5952">
              <rect width="66.9387" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </section>
</template>



<script>
import { checkVideosForPWA } from './pwaVideoManager';
import { inject } from 'vue';

export default {
  name: "App",
  data() {
    return {
      showNav: true
    };
  },
  mounted: () => {
    checkVideosForPWA();
  }
};
</script>

<style>
/* Ajoutez vos styles globaux ici */
a {
  color: #E06469;
  font-size: 26px;
}

nav {
  display: none;
}

h1 {
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  color: white;
}
</style>