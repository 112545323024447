<template>
    <div class="page-accueil">

        <!-- MENU LANGUES -->
        <div class="menu-container">
            <button class="menu-button" ref="menuButton">
                {{ $t("LANGAGES.SHORT.ACTUAL") }} <i class="fas fa-angle-up"></i>
            </button>
            <div class="menu" ref="menu">
                <a @click="changeLang('en')">ENGLISH</a>
                <a @click="changeLang('de')">DEUTSCH</a>
                <a @click="changeLang('es')">ESPAÑOL</a>
                <a @click="changeLang('it')">ITALIANO</a>
                <a @click="changeLang('zh')">繁體中文</a>
            </div>
        </div>
        <!-- MENU LANGUES -->

        <!-- TERMS OF USE -->
        <button id="btnTerms" @click="openOverlay">TERMS OF USE</button>

        <div v-if="showOverlay" class="bgTerms">
            <div class="overlay">
                <div class="content">
                    <!-- <p>{{ longText }}</p> -->
                    <p v-html='$t("HOME_PAGE.TERMS")'></p>
                </div>
                <button class="close-button" @click.stop="closeOverlay">CLOSE</button>
            </div>
        </div>
        <!-- TERMS OF USE -->


        <div class="containerHome">
            <div class="Titre" @click="secretButtonEvent()">
                <h1 v-html='$t("TITLE")'></h1>
            </div>
            <div class="IntroTexte">
                <p class="intro" v-html='$t("HOME_PAGE.SUBTITLE")'></p>
            </div>

            <cached-video class="videoPlankton" unique-id="home-video-acceuil"
                :sources='[{ path: "video/webm/470x470_BIOTHERM_SCIENCE_LIFE_PLANKTON_FINAL-VIDEO.webm", type: "video/webm" }, { path: "video/470x470_BIOTHERM_SCIENCE_LIFE_PLANKTON_FINAL-VIDEO-LR.mov", type: "video/mp4" }]'></cached-video>

            <div v-if="highestScore != null && $route.query.retail == 1" class="scoreHome">
                <p id="highest">HIGHEST SCORE</p>
                <p id="highestScore">{{ highestScore }}</p>
            </div>
            <div class="BottomButton">
                <router-link class="myButton" to="/chooseplankton">{{ $t("HOME_PAGE.START_GAME") }}</router-link>
            </div>
            <div class="OmbreVideo">
                <img id="ombre" src="@/assets/ombre.png" />
            </div>
            <div class="LogoHome">
                <a :href="$t('HOME_PAGE.BIOTHERM_LINK')" target="_blank">
                    <svg id="LogoHomeiPad" width="67" height="12" viewBox="0 0 67 12" fill="none"
                        xmlns="http://www.w3.org/2000/svg">

                        <g clip-path="url(#clip0_392_5931)">
                            <path d="M10.6959 0.205505H8.65869V11.8072H10.6959V0.205505Z" fill="white" />
                            <path
                                d="M23.8858 5.96702C23.8858 2.7019 21.3996 0 17.8376 0C14.2757 0 11.7539 2.73488 11.7539 6V6.03298C11.7539 9.2981 14.2402 12 17.8046 12C21.3691 12 23.8858 9.26512 23.8858 6V5.96702ZM21.7471 6.03298C21.7471 8.28837 20.1387 10.1099 17.8351 10.1099C15.5315 10.1099 13.8901 8.25285 13.8901 6V5.96702C13.8901 3.71163 15.4985 1.89006 17.8021 1.89006C20.1057 1.89006 21.7471 3.74715 21.7471 6V6.03298Z"
                                fill="white" />
                            <path d="M26.2807 11.8021H28.3205V2.08794H31.0351V0.197876H23.5662V2.08794H26.2807V11.8021Z"
                                fill="white" />
                            <path
                                d="M34.1075 6.91077H38.8593V11.8021H40.8991H45.9781V9.978H40.8991V6.87779H44.1667V5.03847H40.8991V2.02198H45.8944V0.197876H40.8991H38.8593V5.02325H34.1075V0.197876H32.0703V11.8021H34.1075V6.91077Z"
                                fill="white" />
                            <path
                                d="M2.03721 11.8021H3.85116C6.16237 11.8021 7.60085 10.4905 7.60085 8.50401V8.4761C7.60085 7.24312 7.04778 6.35517 6.18266 5.81225C6.89302 5.27187 7.36237 4.51331 7.36237 3.52642V3.49851C7.36237 1.5095 5.92389 0.197876 3.61268 0.197876H0V11.7996H2.03721V11.8021ZM2.03721 2.0575H3.67865C4.83552 2.0575 5.52051 2.57758 5.52051 3.53149V3.55686C5.52051 4.4575 4.81015 5.09174 3.69133 5.09174H2.03721V2.0575ZM2.03721 6.81437H3.93235C5.05116 6.81437 5.76152 7.54756 5.76152 8.44819V8.47356C5.76152 9.42747 5.07653 9.94756 3.91966 9.94756H2.03721V6.81437Z"
                                fill="white" />
                            <path
                                d="M52.1278 6.59872C53.4216 6.12938 54.3172 5.09428 54.3172 3.52388V3.49597C54.3172 1.5095 52.8787 0.197876 50.5675 0.197876H46.9548V11.7996H48.992V6.89301H50.0956L52.5997 11.7996H54.817L52.5388 7.3928L52.1278 6.59872ZM50.6487 5.03847H48.9946V2.05496H50.636C51.7929 2.05496 52.4779 2.57504 52.4779 3.52895V3.55432C52.4779 4.45496 51.7675 5.03847 50.6487 5.03847Z"
                                fill="white" />
                            <path
                                d="M65.9088 0H65.683L61.1367 6.88795L56.5878 0H56.3621L55.332 11.8072H57.3286L57.9122 5.27696L61.0251 10.0034H61.2458L64.3587 5.27696L64.9422 11.8072H66.9388L65.9088 0Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_392_5931">
                                <rect width="66.9387" height="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>

        <div class="retail-activated" v-if="retailModeMessageVisibility">
            retail toggled !
        </div>



    </div>
</template>

<script>
import Helper from '@/game/Helper';
import { inject, nextTick } from 'vue';


export default {
    name: "pageAccueil",
    data() {
        return {
            showOverlay: false,
            highestScore: null,
            retail: false,
            secretEventCount: 0,
            retailModeMessageVisibility: false
        }
    },
    mounted() {
        if (!window.localStorage.getItem("allScores")) {
            Helper.defineDefaultScores();
        }

        let allScoresFromMemory = JSON.parse(window.localStorage.getItem("allScores"));
        if (allScoresFromMemory.length) {
            allScoresFromMemory = allScoresFromMemory.sort(Helper.scaleOrderFunction);
            this.highestScore = allScoresFromMemory[0];
        }



        this.$root.showNav = false;

        const menuButton = this.$refs.menuButton;
        const menu = this.$refs.menu;

        if (menuButton && menu) {
            /* ! pas besoins d'event listener dans vue */
            menuButton.addEventListener("click", this.toggleMenu);
            window.addEventListener("click", this.closeMenu);
        } else {
            console.error("Cannot find menuButton or menu elements");
        }
    },
    beforeUnmount() {
        this.$root.showNav = true;

        // Supprimez les écouteurs d'événements pour éviter les fuites de mémoire
        this.$refs.menuButton?.removeEventListener("click", this.toggleMenu);
        window.removeEventListener("click", this.closeMenu);
    },
    methods: {
        yo() {
        },
        openOverlay() {
            this.showOverlay = true;
        },
        closeOverlay() {
            this.showOverlay = false;
        },
        toggleMenu() {
            this.$refs.menu.classList.toggle("open");
        },
        closeMenu(event) {
            if (
                event.target !== this.$refs.menuButton &&
                !this.$refs.menuButton.contains(event.target)
            ) {
                this.$refs.menu.classList.remove("open");
            }
        },
        changeLang(lang) {
            this.$i18n.locale = lang;
            if (this.$route.query.lang != lang) this.$route.query.lang = lang
            window.trackEvent("Localization", {
                props: {
                    lang: lang
                }
            })
        },
        secretButtonEvent() {
            this.secretEventCount++;
            if (this.secretEventCount == 10) {
                //toggle retail
                if (this.$route.query.retail) {
                    this.$route.query.retail = ""
                }
                else {
                    this.$route.query.retail = 1
                    this.retailModeMessageVisibility = true;
                    window.trackEvent("Retail Mode")

                    Helper.initRetailMode()
                    setTimeout(() => {
                        this.retailModeMessageVisibility = false;
                    }, 2000);
                }

                this.$router.replace({ query: this.$route.query })
            }
        }
    },
    setup() {
        const plausible = inject('plausible');
        return {
            plausible,
        };
    },
};
</script>


<style scoped>
/* iPhone SE */
@media only screen and (max-width: 380px) {

    /* CSS apply on width between 0 and 500px */

    .LogoHome {
        order: 0;
        width: 67px;
        height: 12px;
        margin-top: 2%;
    }

    .videoPlankton {
        order: 3;
        width: 200px;
        height: 200px;
    }

    .menu-container {
        position: fixed;
        bottom: 5px;
        right: 5px;
        z-index: 0;
    }

    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1.6px;
        text-shadow: none;
    }

    .myButton {
        background-color: #00263a;
        border-radius: 42px;
        cursor: pointer;
        color: #ffffff;
        font-family: "FuturaLTLight";
        font-size: 10px;
        letter-spacing: 0.6px;
        padding: 12px 22px;
        text-decoration: none;
        position: relative;
        left: 0;
        right: 0;
        text-align: center;
        width: 95px;
        z-index: 0;
    }

    .IntroTexte {
        order: 2;
        width: 190px;
        height: 50px;
        margin-bottom: 0;
    }

    .intro {
        font-size: 13px;
        line-height: 17px;

    }

    .Titre {
        order: 1;
        width: 300px;
        height: 65px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

}

/* iPhone */
@media only screen and (max-width: 600px) and (min-width: 381px) {

    /* CSS apply on width between 501px and 600px */

    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 1.6px;
        text-shadow: none;
    }

    .LogoHome {
        order: 0;
        width: 67px;
        height: 12px;
        margin-top: 10%;
    }

    .videoPlankton {
        order: 3;
        width: 300px;
        height: 300px;
    }

    .Titre {
        order: 1;
        width: 300px;
        height: 80px;
        margin-bottom: 20px;
    }


}





.retail-activated {
    position: absolute;
    top: 10px;
    left: 10px;
    visibility: hidden;
    color: yellow;
    font-weight: bold;
}

.retail-activated,
.visible {
    visibility: visible;
}

.page-accueil {
    background-image: radial-gradient(70% 40% at 50% 47%,
            #063770 0%,
            #073aff00 100%),
        radial-gradient(113% 91% at 17% -2%, #05376b 1%, #ff000000 99%),
        radial-gradient(142% 91% at 83% 7%, #025e9f 1%, #ff000000 99%),
        radial-gradient(142% 91% at -6% 74%, #0b5394 1%, #ff000000 99%),
        radial-gradient(142% 91% at 111% 84%, #0e85ce 0%, #0e85ce 100%);

    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
}


.link-partie {
    color: white;
}

.myButton {
    /* z-index: -1; */
}

video {
    width: 100%;
}


.containerHome {
    width: 100%;
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}





.IntroTexte {
    order: 2;
    width: 190px;
    height: 50px;
    /* margin-bottom: 20px; */
}

/* .videoPlankton {
    order: 3;
    width: 300px;
    height: 300px;
} */

.scoreHome {
    display: none;
}

.OmbreVideo {
    order: 4;
    width: 167px;
    height: 5px;
    margin-bottom: 20px;
    /* padding-top: 5px; */
}

#ombre {
    width: 100%;
}

/*terms of use*/
.bgTerms {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

#btnTerms {
    position: fixed;
    bottom: 0;
    padding-bottom: 5px;
    background: none;
    border: none;
    color: #fff;
    font-size: 12px;
}

.overlay {
    background: #fff;
    width: 85%;
    height: 85%;
    top: 10%;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 5s ease-in-out;
    border-radius: 8px;

    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.content {
    max-height: 85%;
    overflow-y: scroll;
    box-shadow: inset 0px 0px 34px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 10px;
    margin: 20px;
    font-family: "FuturaLTLight";
    font-size: 12px;
    line-height: 14px;
}

.close-button {
    font-family: "FuturaLTLight";
    align-self: center;
    margin-bottom: 10px;
    width: 120px;
    text-align: center;
    background-color: #696969;
    border-radius: 28px;
    border: none;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 12px;
    padding: 13px 31px;
    text-decoration: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}






/*IPAD*/

@media screen and (min-width: 40em) {
    .Titre {
        width: 400px;
        height: 150px;
    }

    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 69px;
        letter-spacing: 2.5px;
        text-shadow: none;
    }

    svg {
        transform: scale(2.5);
    }

    .videoPlankton {
        order: 2;
        width: 480px;
        height: 480px;
    }

    .IntroTexte {
        width: 100%;
        height: 30px;
    }

    .scoreHome {
        display: block;
        order: 3;
        width: 300px;
        height: 95px;
    }

    #highest {
        text-align: center;
        color: #ffffff;
        font-family: "FuturaLTLight";
        letter-spacing: 1px;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
    }

    #highestScore {
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);
        font-family: "FuturaLTLight";
        letter-spacing: 2px;
        font-size: 50px;
        line-height: 69px;
        margin: 0;
    }
}
</style>
