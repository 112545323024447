<template>
    <div class="page-loading">

        <div class="gridLoading">
            <div class="gridLoadingTitre">
                <h1 v-html='$t("LOADING_PAGE.TITLE")'></h1>
            </div>
            <div class="gridLoadingAnim">
                <Vue3Lottie class="lottie-animation" :animationData="Loader" :height="230" :width="230" />
            </div>
            <div class="gridLoadingBar">
                <progress max="100" :value="progress"></progress>
            </div>
            <div class="gridLoadingText">
                <p class="intro">{{ $t("LOADING_PAGE.QUESTION") }}<br><br>
                    {{ fact }}
                </p>
            </div>
            <div class="gridLoadingCopyright">
                <p class="copyright">Copyright © Biotherm 2023</p>
            </div>
        </div>

    </div>
</template>


<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import Loader from '@/assets/loading/loader.json'
import Helper from '@/game/Helper'

export default {
    name: "pageLoading",
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            Loader,
            progress: 0,
            trueProgress: 0,
            progressInterval: null,
            gameQuantityMax: 100//100
        }
    },
    created() {
        this.fact = this.getFact()
    },
    computed: {
    },
    methods: {
        addTrueProgress(value) {
            this.trueProgress += value;
        },
        skipLoading() {
            this.progressInterval = setInterval(() => {
                if (this.progress < 100) {
                    this.progress += 1;
                } else if (this.progress >= 100) {
                    clearInterval(this.progressInterval);
                    this.$emit("loadingOver");
                }
            }, 20);
        },
        getFact() {
            return this.$t("LOADING_PAGE.FACTS." + Helper.randomIntFromInterval(0, 5))
        },
        startProgress() {
            this.progressInterval = setInterval(() => {
                if (this.progress < this.trueProgress) {
                    this.progress += 1;
                } else if (this.progress >= 100) {
                    clearInterval(this.progressInterval);
                    this.$emit("loadingOver");
                }
            }, 28);//28
        },
    },
    mounted() {
        this.startProgress();
    },
}
</script>


<style scoped>
/* iPhone SE */
@media only screen and (max-width: 380px) {
    .gridLoading {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 107px 123px 79px 107px 55px;
        grid-column-gap: 0px;
        grid-row-gap: 10px;
    }

    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1.6px;
        text-shadow: none;
    }
}

/* iPhone */
@media only screen and (max-width: 600px) and (min-width: 381px) {
    h1 {
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 1.6px;
        text-shadow: none;
    }
}

.page-loading {
    display: none;
    background-image: radial-gradient(70% 40% at 50% 47%,
            #063770 0%,
            #073aff00 100%),
        radial-gradient(113% 91% at 17% -2%, #05376b 1%, #ff000000 99%),
        radial-gradient(142% 91% at 83% 7%, #025e9f 1%, #ff000000 99%),
        radial-gradient(142% 91% at -6% 74%, #0b5394 1%, #ff000000 99%),
        radial-gradient(142% 91% at 111% 84%, #0e85ce 0%, #0e85ce 100%);

    overscroll-behavior-y: contain;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.lottie-animation {
    width: 200px;
    height: 200px;
    filter: drop-shadow(0px 4px 44px rgba(255, 255, 255, 0.5));
}

/* h1 {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 1.6px;
    color: #FFFFFF;
} */

.intro {
    padding: 0 20px 20px;
}

progress {
    -webkit-appearance: none;
}

::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.50);
    height: 5px;
}

::-webkit-progress-value {
    background-color: white;
}

@media screen and (min-width: 40em) {


    .gridLoading {
        grid-template-rows: 192px 146px 105px 206px 88px;
    }

    h1 {
        font-size: 50px;
        line-height: 69px;
        letter-spacing: 2.5px;
        text-align: center;
        font-family: "FuturaLTMedium";
        color: white;
        font-style: normal;
        font-weight: 400;

    }

    .intro {
        padding: 0 150px 0 150px;
    }
}
</style>